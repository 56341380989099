import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auto: {
    loading: true,
    error: null,
    data: null,
  },
  autocarro: {
    loading: true,
    error: null,
    data: null,
  },
  infortuni: {
    loading: true,
    error: null,
    data: null,
  },
  professionale: {
    loading: true,
    error: null,
    data: null,
  },
  infodrive: {
    loading: true,
    error: null,
    data: null,
  },
  travel: {
    loading: true,
    error: null,
    data: null,
  },
  moto: {
    loading: true,
    error: null,
    data: null,
  },
};

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    autoResultsPending: (state) => {
      state.auto.loading = true;
      state.auto.error = null;
    },
    autoResultsRejected: (state, { payload }) => {
      state.auto.loading = false;
      state.auto.error = payload;
    },
    autoResultsFulfilled: (state, { payload }) => {
      state.auto.loading = false;
      state.auto.data = payload;
    },

    autocarroResultsPending: (state) => {
      state.autocarro.loading = true;
      state.autocarro.error = null;
    },
    autocarroResultsRejected: (state, { payload }) => {
      state.autocarro.loading = false;
      state.autocarro.error = payload;
    },
    autocarroResultsFulfilled: (state, { payload }) => {
      state.autocarro.loading = false;
      state.autocarro.data = payload;
    },

    infortuniResultsPending: (state) => {
      state.infortuni.loading = true;
      state.infortuni.error = null;
    },
    infortuniResultsRejected: (state, { payload }) => {
      state.infortuni.loading = false;
      state.infortuni.error = payload;
    },
    infortuniResultsFulfilled: (state, { payload }) => {
      state.infortuni.loading = false;
      state.infortuni.data = payload;
    },
    infortuniExtensionsPending: (state) => {
      state.infortuni.loading = true;
      state.infortuni.error = null;
    },
    infortuniExtensionsRejected: (state, { payload }) => {
      state.infortuni.loading = false;
      state.infortuni.error = payload;
    },
    infortuniExtensionsFulfilled: (state) => {
      state.infortuni.loading = false;
    },

    professionaleResultsPending: (state) => {
      state.professionale.loading = true;
      state.professionale.error = null;
    },
    professionaleResultsRejected: (state, { payload }) => {
      state.professionale.loading = false;
      state.professionale.error = payload;
    },
    professionaleResultsFulfilled: (state, { payload }) => {
      state.professionale.loading = false;
      state.professionale.data = payload;
    },

    infodriveResultsPending: (state) => {
      state.infodrive.loading = true;
      state.infodrive.error = null;
    },
    infodriveResultsRejected: (state, { payload }) => {
      state.infodrive.loading = false;
      state.infodrive.error = payload;
    },
    infodriveResultsFulfilled: (state, { payload }) => {
      state.infodrive.loading = false;
      state.infodrive.data = payload;
    },
    travelResultsPending: (state) => {
      state.travel.loading = true;
      state.travel.error = null;
    },
    travelResultsRejected: (state, { payload }) => {
      state.travel.loading = false;
      state.travel.error = payload;
    },
    travelResultsFulfilled: (state, { payload }) => {
      state.travel.loading = false;
      state.travel.data = payload;
    },
    motoResultsPending: (state) => {
      state.moto.loading = true;
      state.moto.error = null;
    },
    motoResultsRejected: (state, { payload }) => {
      state.moto.loading = false;
      state.moto.error = payload;
    },
    motoResultsFulfilled: (state, { payload }) => {
      state.moto.loading = false;
      state.moto.data = payload;
    },
  },
});
