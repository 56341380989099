import { BACKEND_BASE_URL } from '../../../../configs/urls';
import { Card } from './styles/common.styles';
import { guideTypes } from './utils/guide_types';

const coverageName = {
  rca: 'Responsabilità civile',
  furto_incendio: 'Furto e Incendio',
  eventi_naturali: 'Eventi Naturali',
  assistenza_stradale: 'Assistenza stradale',
  eventi_sociopolitici: 'Atti vandalici',
  tutela_legale: 'Tutela legale',
  cristalli: 'Cristalli',
  collisione: 'Kasco collisione',
  kasko: 'Kasco completa',
  infortuni_conducente: 'Infortuni conducente',
  rivalsa: 'Zero rivalsa per ebbrezza',
};

const getPaymentFrequency = (paymentFrequency) => {
  const pf = {
    1: 'Annuo',
    2: 'Semestrale',
  };

  return pf[paymentFrequency];
};

const guaranteesPrima = (quote) => {
  const guarantees = quote.quotation_data_prima_guarantees.map((guarantee) => guarantee.slug).filter((g) => g != 'rca' && g != 'furto_incendio');
  if (quote.quotation_data_prima_guarantees.find((qg) => qg.slug == 'furto_incendio') != undefined) {
    guarantees.push('incendio');
    guarantees.push('furto');
  }
  return guarantees;
};

const convertToPrimaCoverages = (coverages) => {
  const cov = ['rca', ...coverages].filter(
    (c) => !['furto', 'incendio'].includes(c),
  );
  if (coverages.includes('furto') || coverages.includes('incendio')) {
    cov.push('furto_incendio');
  }
  return cov;
};

const calcAmount = (quotation_data_prima_guarantees, primaCoverages, key) => {
  const prices = quotation_data_prima_guarantees.find(
    (f) => f.slug === key,
  ).quotation_data_prima_guarantee_prices;
  const filterPrice = prices.filter(
    (p) => p.required_guarantees === ''
        || p.required_guarantees.split(',').every((e) => primaCoverages.includes(e)),
  );
  return filterPrice.reduce((prev, curr) => (prev.discounted < curr.discounted ? prev : curr));
};

const coverageAmount = (
  { quotation_data_prima: { quotation_data_prima_guarantees } },
  primaCoverages,
  coverageSlug,
) => calcAmount(
  quotation_data_prima_guarantees,
  primaCoverages,
  coverageSlug,
);

const coverageLimitName = (
  { quotation_data_prima: { quotation_data_prima_guarantees } },
  coverageSlug,
) => quotation_data_prima_guarantees.find((s) => s.slug === coverageSlug)
  .limits_name;

function PrimaCard({
  insuranceRequest, quotation, coverages, aquista,
}) {
  const primaPrices = {};
  let primaAllowedCoverages = []; // to know what coverages prima have

  quotation.quotation_data_prima.quotation_data_prima_guarantees.forEach((guarantee) => {
    primaAllowedCoverages.push(guarantee.slug);
    guarantee.quotation_data_prima_guarantee_prices.forEach((price) => {
      if (price.required_guarantees !== '') {
        const g = price.required_guarantees.split(',');
        primaAllowedCoverages = [...primaAllowedCoverages, ...g];
      }
    });
    primaPrices[guarantee.slug] = guarantee.quotation_data_prima_guarantee_prices;
  });

  primaAllowedCoverages = guaranteesPrima(quotation.quotation_data_prima);
  const convertedPrimaAllowedCoverages = convertToPrimaCoverages(
    primaAllowedCoverages,
  );
  const primaConvertedCoverages = quotation.is_aquista
    ? ['rca', ...quotation.guarantees.map((guarantee) => guarantee.guarantee_slug)]
    : convertToPrimaCoverages(coverages);
  const checkIfHaveCoverages = () => {
    let includes = true;
    coverages.forEach((coverage) => {
      if (primaAllowedCoverages.indexOf(coverage) === -1) {
        includes = false;
      }
    });
    return includes;
  };

  const hasCoverages = checkIfHaveCoverages();

  const calculatedPrices = convertedPrimaAllowedCoverages
    .filter((adr) => {
      if (adr === 'rca') return true;

      for (let i = 0; i < primaConvertedCoverages.length; i++) {
        if (adr === primaConvertedCoverages[i]) return true;
      }
      return false;
    })
    .map((guarantee) => {
      const price = coverageAmount(quotation, primaConvertedCoverages, guarantee);

      return {
        name: coverageName[guarantee],
        limits_name: coverageLimitName(quotation, guarantee),
        discounted: price.discounted,
        discounted_to_display: price.discounted_to_display,
      };
    });

  return (
    <Card isAquista={quotation.is_aquista}>
      <img className="company-logo" src={BACKEND_BASE_URL + quotation.company.logo} />
      <div className="information">
        <div className="information-row">
          <div className="information-label">Compagnia:</div>
          <div className="information-value">Ipitq ma</div>
        </div>
        <div className="information-row">
          <div className="information-label">Risarcimento:</div>
          <div className="information-value">Rc con Risarcimento Diretto e RFS</div>
        </div>
        <div className="information-row">
          <div className="information-label">Emissione:</div>
          <div className="information-value">Diretta</div>
        </div>
        <div className="information-row">
          <div className="information-label">Massimale:</div>
          <div className="information-value">
            6'450'000€ Danni a persone
            {' '}
            <br />
            1'050'000€ Danni a cose
          </div>
        </div>
        <div className="information-row">
          <div className="information-label">Guida:</div>
          <div className="information-value">{guideTypes.find((v) => v.id === insuranceRequest.guide_type).name}</div>
        </div>
        <div className="information-row">
          <div className="information-label">Frequenza di pagamento:</div>
          <div className="information-value">
            <div className="badge badge-info">{getPaymentFrequency(quotation.payment_frequency)}</div>
          </div>
        </div>
        <div className="information-row">
          <div className="information-label">Garanzie Incluse:</div>
          <div className="information-value">
            {calculatedPrices.map((guarantee) => (
              <div className="information-guarantee" key={guarantee}>
                <span>
                  {guarantee.name}
                  :
                </span>
                <span>
                  €
                  {' '}
                  {Number(
                    guarantee.discounted_to_display,
                  ).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pricing">
        {hasCoverages === false
                && <div>Prima non incude questa garanzia!</div>}
        {hasCoverages === true
                && (
                <>
                  <div className="pricing-label">Prezzo Annuo</div>
                  {quotation.amount_before_discount !== null
                        && quotation.amount_before_discount !== quotation.amount
                        && quotation.amount_before_discount > quotation.amount
                        && (
                        <div className="pricing-discount">
                          €
                          {' '}
                          {Number(
                            quotation.amount_before_discount,
                          ).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                        )}
                  <div className="pricing-amount">
                    €
                    {' '}
                    {Number(
                      quotation.amount,
                    ).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                  <div className="aquista-button" onClick={() => aquista()}>
                    Acquista
                  </div>
                </>
                )}
      </div>
    </Card>
  );
}

export default PrimaCard;
