import LoadingGif from '../../assets/images/loading.gif';
import { PageLoadingStyles } from './PageLoading.styles';

export default function PageLoading() {
  return (
    <PageLoadingStyles>
      <div className="d-flex content-loading flex-column">
        <div id="img-container" className="text-center">
          <img className="loading-gif" src={LoadingGif} />
        </div>
        <div id="text">Attendere prego</div>
      </div>
    </PageLoadingStyles>
  );
}
