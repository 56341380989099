import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auto: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
  autocarro: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
  moto: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
  infortuni: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
  professionale: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
  infodrive: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
  travel: {
    loading: true,
    error: null,
    quotation: null,
    insuranceRequest: null,
  },
};

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    getAutoPurchasePending: (state) => {
      state.auto.loading = true;
    },
    getAutoPurchaseRejected: (state, { payload }) => {
      state.auto.loading = false;
      state.auto.error = payload;
    },
    getAutoPurchaseFulfilled: (state, { payload }) => {
      state.auto.loading = false;
      state.auto.quotation = payload.quotation;
      state.auto.insuranceRequest = payload.insuranceRequest;
    },

    getAutocarroPurchasePending: (state) => {
      state.autocarro.loading = true;
    },
    getAutocarroPurchaseRejected: (state, { payload }) => {
      state.autocarro.loading = false;
      state.autocarro.error = payload;
    },
    getAutocarroPurchaseFulfilled: (state, { payload }) => {
      state.autocarro.loading = false;
      state.autocarro.quotation = payload.quotation;
      state.autocarro.insuranceRequest = payload.insuranceRequest;
    },

    getMotoPurchasePending: (state) => {
      state.moto.loading = true;
    },
    getMotoPurchaseRejected: (state, { payload }) => {
      state.moto.loading = false;
      state.moto.error = payload;
    },
    getMotoPurchaseFulfilled: (state, { payload }) => {
      state.moto.loading = false;
      state.moto.quotation = payload.quotation;
      state.moto.insuranceRequest = payload.insuranceRequest;
    },

    getInfortuniPurchasePending: (state) => {
      state.infortuni.loading = true;
    },
    getInfortuniPurchaseRejected: (state, { payload }) => {
      state.infortuni.loading = false;
      state.infortuni.error = payload;
    },
    getInfortuniPurchaseFulfilled: (state, { payload }) => {
      state.infortuni.loading = false;
      state.infortuni.quotation = payload.quotation;
      state.infortuni.insuranceRequest = payload.insuranceRequest;
    },

    getProfessionalePurchasePending: (state) => {
      state.professionale.loading = true;
    },
    getProfessionalePurchaseRejected: (state, { payload }) => {
      state.professionale.loading = false;
      state.professionale.error = payload;
    },
    getProfessionalePurchaseFulfilled: (state, { payload }) => {
      state.professionale.loading = false;
      state.professionale.quotation = payload.quotation;
      state.professionale.insuranceRequest = payload.insuranceRequest;
    },

    getInfodrivePurchasePending: (state) => {
      state.infodrive.loading = true;
    },
    getInfodrivePurchaseRejected: (state, { payload }) => {
      state.infodrive.loading = false;
      state.infodrive.error = payload;
    },
    getInfodrivePurchaseFulfilled: (state, { payload }) => {
      state.infodrive.loading = false;
      state.infodrive.quotation = payload.quotation;
      state.infodrive.insuranceRequest = payload.insuranceRequest;
    },

    getTravelPurchasePending: (state) => {
      state.travel.loading = true;
    },
    getTravelPurchaseRejected: (state, { payload }) => {
      state.travel.loading = false;
      state.travel.error = payload;
    },
    getTravelPurchaseFulfilled: (state, { payload }) => {
      state.travel.loading = false;
      state.travel.quotation = payload.quotation;
      state.travel.insuranceRequest = payload.insuranceRequest;
    },
  },
});
