import Swal from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

export const generalWarningAlert = (text) => {
  Swal.fire({
    icon: 'warning',
    title: 'Attenzione!',
    text,
    confirmButtonText: 'Ok',
  });
};
export const generalAlertSuccessToast = (message) => {
  Swal.fire({
    icon: 'success',
    title: 'Successo',
    toast: true,
    position: 'bottom-right',
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertSuccess = (message) => {
  Swal.fire({
    icon: 'success',
    title: 'Successo',
    text: message,
  });
};

export const generalAlertError = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Errore',
    text: message,
  });
};

export const confirmAlert = (message, action) => {
  Swal.fire({
    title: 'Are you sure?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, I am sure!',
    cancelButtonText: 'No, cancel it!',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    }
  });
};

export const confirmAlertForBackofficeBrokerQuote = (
  message,
  action,
  navigate,
  distanceToken,
) => {
  Swal.fire({
    title: 'Sei sicuro di procedere?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff0000',
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancella!',
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    } else {
      navigate(`/request/${distanceToken}/auto/results`);
    }
  });
};
