import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid
    ${({ isAquista }) => (isAquista === 1 ? 'var(--greenia-color)' : 'grey')};
  border-radius: 10px;

  .company-logo {
    padding: 20px;
    width: 100%;
    margin: auto;
    max-width: 200px;
    object-fit: contain;
  }

  .information {
    padding: 5px;
    width: 100%;
    max-width: 500px;
    padding: 10px 20px;
    margin: auto;

    .information-row {
      display: flex;
      font-size: 14px;
    }

    .information-label {
      margin: 0 2px;
      font-weight: bold;
      width: calc(40% - 4px);
    }

    .information-value {
      margin: 0 2px;
      width: calc(60% - 4px);
    }

    .badge-info {
      background-color: var(--greenia-color);
      padding: 6px 30px;
    }

    .information-guarantee {
      display: flex;
      justify-content: space-between;
      margin: 3px;
      padding: 1px 5px;
      font-size: 11px;
      font-weight: bold;
      color: white;
      background-color: var(--greenia-color);
      border-radius: 5px;
      text-align: center;
    }
  }

  .pricing {
    width: 100%;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .pricing-label {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .pricing-discount {
      text-align: center;
      text-decoration: line-through;
      color: coral;
      font-weight: bold;
    }

    .pricing-amount {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: red;
      font-weight: bold;
      font-size: 35px;
    }

    .pricing-amount .sub {
      text-align: center;
      font-size: 14px;
    }

    .aquista-button {
      margin-top: 10px;
      padding: 10px 20px;
      width: 100%;
      background-color: var(--greenia-color);
      color: white;
      font-weight: bold;
      border: 2px solid var(--greenia-color);
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
    }

    .aquista-button:hover {
      color: var(--greenia-color);
      background-color: white;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    .company-logo {
      width: 100%;
      max-height: 100px;
    }

    .information {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .information-row {
      flex-direction: column;
      align-items: center;
    }

    .information-label {
      text-align: center;
    }

    .information-value {
      text-align: center;
    }

    .pricing {
      max-width: unset;
    }
  }

  @media (max-width: 400px) {
    .information {
      padding: 5px;
    }
  }
`;
