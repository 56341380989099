import React from 'react';
import { FooterStyles } from './Footer.styles';

function Footer() {
  return (
    <FooterStyles>
      <p>
        Il servizio di intermediazione assicurativa è offerto da Greenia Srl , Agente assicurativo regolamentato dall'
        <a href="https://www.ivass.it/">IVASS</a>
        {' '}
        ed iscritto al RUI con numero A000714748 il 23 settembre 2022, P.IVA 16804361000, Indirizzo: VIA ANGELO SECCHI 4 - 00197 ROMA (RM)  Pec
        <a href="mailto:greeninsuranceagency@legalmail.it">greeninsuranceagency@legalmail.it</a>
        {' '}
        - Telefono: +39 3404214475
      </p>
    </FooterStyles>
  );
}

export default Footer;
