import styled from 'styled-components';

export const FooterStyles = styled.footer`
  width: 100%;
  background-color: #00a551;
  padding: 20px 30px;

  p {
    max-width: 900px;
    margin: auto;
    margin-bottom: 0;
    text-align: center;
    font-size: 18px;
    color: #fff;

    a {
      color: #fff;
      font-weight: bold;
    }
  }

  @media (max-width: 900px) {
    p {
      font-size: 14px;
    }
  }
`;
