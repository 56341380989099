import styled from 'styled-components';

export const InfodriveResultsScreenStyles = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 1440px;
  width: 80%;
  margin: 0 auto auto auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const HeadingInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;

  h2 {
    align-self: flex-start;
    border-bottom: 1px solid #00a551;
    padding-bottom: 10px;
    width: 100%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;

    & > div {
      width: 100%;
    }

    h2 {
      font-size: 22px;
      align-self: flex-start;
      border-bottom: 1px solid #00a551;
      padding-bottom: 10px;
      width: 100%;
    }
  }
`;

export const ClientInfoList = styled.ul`
  list-style: none;

  li {
    border-bottom: 1px solid gray;
    padding: 10px 10px 10px 0;
  }

  @media (max-width: 800px) {
    padding-left: 0px;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: 30px;
  max-width: 1000px;
  margin: auto;

  p {
    margin: 0 0 20px 0;
    padding: 0;
  }
`;

export const ResultsContainerRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

export const ResultsContainerColOne = styled.div`
  border-radius: 10px;
  width: 70%;
  padding: 20px;
  height: 100%;

  @media (max-width: 1400px) {
    width: 100%;
    order: 2;
  }

  h1 {
    text-align: center;
  }
`;

export const ResultsContainerColTwo = styled.div`
  border: 1px solid gray;
  border-radius: 10px;
  width: 30%;
  padding: 20px;

  @media (max-width: 1400px) {
    width: 100%;
    order: 1;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CardCol = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.company-logo {
    width: 70%;
  }

  &.garanzie {
    align-items: flex-start;

    h4 {
      border-bottom: 1px solid gray;
      margin-bottom: 5px;
      padding-bottom: 5px;
      width: 100%;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 10px;

      li {
        padding: 2px 2px 2px 0px;
      }
    }
  }
`;

export const PriceCol = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h5 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
  }

  h3 {
    font-size: 32px;
    font-weight: bold;
    color: red;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    border-radius: 10px;
    background-color: #00a551;
    border: 2px solid #00a551;
    padding: 6px 20px;
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    transition: all 250ms ease-in-out;

    &:hover {
      background-color: transparent;
      color: #00a551;
    }
  }
`;

export const AddedExtensionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  h6 {
    margin-bottom: 0;
  }
`;

export const AddedExtensionButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  padding: 8px;
  background-color: #00a551;
  color: #ffffff;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 10px;
`;

export const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: white;
  background-color: red;
  cursor: pointer;
`;

export const ExtensionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: auto;
  li {
    padding: 8px 8px 8px 8px;
    border: 1px solid transparent;
    background-color: transparent;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    margin: 5px 0;

    &.selected {
      background-color: #00a551;
      color: #ffffff;
      border-radius: 10px;
      padding-left: 8px;

      div {
        width: 30px;
        height: 30px;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
    }

    div {
      width: 30px;
      height: 30px;
      border: 1px solid #00a551;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
  }
`;

export const ExtensionsButton = styled.button`
  width: 100%;
  background-color: #00a551;
  border: 1px solid #00a551;
  color: #ffffff;
  padding: 5px;
  border-radius: 10px;
  transition: all 250ms ease-in-out;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: transparent;
    color: #00a551;
  }
`;

export const ExtensionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
    border-bottom: 2px solid #00a551;
    padding-bottom: 10px;
  }
`;

export const AddedExtensionNotif = styled.p`
  text-align: center;
  margin-bottom: auto !important;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  color: #00a551;
  border: 2px solid #00a551;
  padding: 10px 20px !important;
`;
