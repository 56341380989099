import caller from './purchaseCaller';
import { backAxe } from '../../services/axios-client/backAxeClient';
import { generalAlertSuccess } from '../../utils/alert';

/**
 * Infortuni
 */
const notFoundErrorMessage = 'Non siamo riusciti a trovare la richiesta o il collegamento è scaduto';

export const getInfortuniPurchase = async (distanceToken, quotationId) => {
  caller.getInfortuniPurchasePending();

  const response = await backAxe.get(
    `/infortuni/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getInfortuniPurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getInfortuniPurchaseRejected('An error occured');
    return;
  }

  caller.getInfortuniPurchaseFulfilled({
    quotation: response.data.quotation,
    insuranceRequest: response.data.insurance_request,
  });
};

export const infortuniResendInvalidSignedDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getInfortuniPurchasePending();

  await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/resend`,
  );
  collectQuoteData();
};

export const infortuniReminderToSignDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getInfortuniPurchasePending();

  const res = await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }

  collectQuoteData();
};

/**
 * Professionale
 */

export const getProfessionalePurchase = async (distanceToken, quotationId) => {
  caller.getProfessionalePurchasePending();

  const response = await backAxe.get(
    `/profession/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getProfessionalePurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getProfessionalePurchaseRejected('An error occured');
    return;
  }

  caller.getProfessionalePurchaseFulfilled({
    quotation: response.data.data.quotation,
    insuranceRequest: response.data.data.insurance_request,
  });
};

export const professionaleResendInvalidSignedDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getProfessionalePurchasePending();

  await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/resend`,
  );
  collectQuoteData();
};

export const professionaleReminderToSignDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getProfessionalePurchasePending();

  const res = await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }

  collectQuoteData();
};

/**
 * Auto
 */

export const getAutoPurchase = async (distanceToken, quotationId) => {
  caller.getAutoPurchasePending();

  const response = await backAxe.get(
    `/vehicle/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getAutoPurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getAutoPurchaseRejected('An error occured');
    return;
  }

  caller.getAutoPurchaseFulfilled({
    quotation: response.data.data,
    insuranceRequest: response.data.data.insurance_request,
  });
};

export const autoResendInvalidSignedDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getAutoPurchasePending();

  await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/resend`,
  );
  collectQuoteData();
};

export const autoReminderToSignDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getAutoPurchasePending();

  const res = await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }

  collectQuoteData();
};

export const confirmSelectedForBackofficeQuote = async (
  quoteId,
  collectQuoteData,
) => {
  const res = await backAxe.post(`/quotations/backoffice/selected/${quoteId}`);

  if (res.status === 200) {
    collectQuoteData();
  }
};

/**
 * Autocarro
 */

export const getAutocarroPurchase = async (distanceToken, quotationId) => {
  caller.getAutocarroPurchasePending();

  const response = await backAxe.get(
    `/vehicle/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getAutocarroPurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getAutocarroPurchaseRejected('An error occured');
    return;
  }

  caller.getAutocarroPurchaseFulfilled({
    quotation: response.data.data,
    insuranceRequest: response.data.data.insurance_request,
  });
};

export const autocarroResendInvalidSignedDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getAutocarroPurchasePending();

  await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/resend`,
  );
  collectQuoteData();
};

export const autocarroReminderToSignDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getAutocarroPurchasePending();

  const res = await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }

  collectQuoteData();
};

/**
 * Moto
 */

export const getMotoPurchase = async (distanceToken, quotationId) => {
  caller.getMotoPurchasePending();

  const response = await backAxe.get(
    `/moto/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getMotoPurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getMotoPurchaseRejected('An error occured');
    return;
  }

  caller.getMotoPurchaseFulfilled({
    quotation: response.data.data,
    insuranceRequest: response.data.data.insurance_request,
  });
};

export const motoResendInvalidSignedDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getMotoPurchasePending();

  await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/resend`,
  );
  collectQuoteData();
};

export const motoReminderToSignDocs = async (
  distanceToken,
  quotationId,
  collectQuoteData,
) => {
  caller.getMotoPurchasePending();

  const res = await backAxe.post(
    `/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents/reminder`,
  );

  if (res.status === 200) {
    generalAlertSuccess('Un email e stato mando al cliente!');
  }

  collectQuoteData();
};

/**
 * Infodrive
 */

export const getInfodrivePurchase = async (distanceToken, quotationId) => {
  caller.getInfodrivePurchasePending();

  const response = await backAxe.get(
    `/infodrive/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getInfodrivePurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getInfodrivePurchaseRejected('An error occured');
    return;
  }

  caller.getInfodrivePurchaseFulfilled({
    quotation: response.data.data.quotation,
    insuranceRequest: response.data.data.insurance_request,
  });
};

/**
 * Travel
 */

export const getTravelPurchase = async (distanceToken, quotationId) => {
  caller.getTravelPurchasePending();

  const response = await backAxe.get(
    `/travel/request/${distanceToken}/quotations/${quotationId}/purchase`,
  );

  if (response.status === 404) {
    caller.getTravelPurchaseRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.getTravelPurchaseRejected('An error occured');
    return;
  }

  caller.getTravelPurchaseFulfilled({
    quotation: response.data.data.quotation,
    insuranceRequest: response.data.data.insurance_request,
  });
};
