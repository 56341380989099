import styled from 'styled-components';

export const UppyContainer = styled.div`
    margin: 5px;
    
    .uppy-Dashboard-inner {
        height: 200px !important;
        max-width: 350px;
    }

    .uppy-Dashboard-AddFiles-info {
        display: block;
    }

    .uppy-Dashboard-AddFiles-info > a {
        display: none;
    }
`;
