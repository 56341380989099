import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container, HeadingInfo,
  InfodriveResultsScreenStyles, ResultsContainer,
} from './InfodriveResultsScreen.styles';
import { getInfodriveResult } from '../../../features/results/resultsActions';
import PageLoading from '../../../components/PageLoading/PageLoading';
import Footer from '../../../components/Reusable/Footer';
import Header from '../../../components/Reusable/Header';
import InfodriveCard from '../cards/vehicles/InfodriveCard';

function InfodriveResultsScreen() {
  const { distanceToken } = useParams();
  const state = useSelector((store) => store.results.infodrive);
  const { loading, error, data } = state;
  const navigate = useNavigate();

  const aquista = (quotationId) => {
    navigate(`/request/${distanceToken}/infodrive/${quotationId}/purchase`);
  };

  useEffect(() => {
    getInfodriveResult(distanceToken);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return (
      <div className="alert alert-danger m-5">
        {error}
      </div>
    );
  }

  return (
    <InfodriveResultsScreenStyles>
      <Header />
      <Container>
        <HeadingInfo>
          <h2>
            Preventivi assicurazione Infodrive
          </h2>
        </HeadingInfo>
        <ResultsContainer>
          <p>
            <FontAwesomeIcon icon={faCalendarDays} />
            {' '}
            Preventivi aggiorni al
            {new Date().toLocaleDateString('it')}
          </p>
          <InfodriveCard
            aquista={() => aquista(data.quote.id)}
            quotation={data.quote}
            insuranceRequest={data.insuranceRequest}
          />
        </ResultsContainer>
      </Container>
      <Footer />
    </InfodriveResultsScreenStyles>
  );
}

export default InfodriveResultsScreen;
