import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container, FlowContentSection, PurchaseScreenStyles, Title,
} from '../styles/common.styles';
import Header from '../../../components/Reusable/Header';
import Footer from '../../../components/Reusable/Footer';
import Stepper from '../../../components/Reusable/Stepper';
import AlertBox from '../../../components/Reusable/AlertBox';
import { Uploader } from '../../../components/Reusable/Uploader/Uploader';
import { DocumentsUploaded } from '../../../components/Reusable/Purchase/DocumentsUploaded/DocumentsUploaded';
import { DocumentsSignCard } from '../../../components/Reusable/Purchase/DocumentsSignCard/DocumentsSignCard';
import {
  autoReminderToSignDocs, autoResendInvalidSignedDocs, confirmSelectedForBackofficeQuote, getAutoPurchase,
} from '../../../features/purchase/purchaseActions';
import PageLoading from '../../../components/PageLoading/PageLoading';
import {
  apiDeleteOptionalDocument, apiDownloadDocument, apiDownloadOptionalDocument, apiOptionalDocumentMethodManualUpload, apiUploadOptionalDocument, deleteDocument, documentMethodManualUpload, selectSignMethod, uploadDocument,
} from '../../../services/axios-client/axeCommons';
import { confirmAlertForBackofficeBrokerQuote, generalAlertError, generalAlertSuccessToast } from '../../../utils/alert';
import { PaymentContainer } from '../../../components/Reusable/Purchase/PaymentContainer/PaymentContainer';
import OptionalDocumentModal from '../../../components/Reusable/Purchase/OptionalDocumentModal/OptionalDocumentModal';
import { Questionnarie } from '../../../components/Reusable/Purchase/Questionnarie/Questionnarie';

function AutoPurchaseScreen() {
  const { distanceToken, quotationId } = useParams();

  const state = useSelector((store) => store.purchase.auto);
  const {
    loading, error, quotation, insuranceRequest,
  } = state;
  const navigate = useNavigate();
  const [showOptionalDocumentModal, setShowOptionalDocumentModal] = useState(false);

  const collectQuoteData = () => {
    getAutoPurchase(distanceToken, quotationId);
  };

  useEffect(() => {
    collectQuoteData();
  }, []);

  useEffect(() => {
    if (loading === false && error === null && quotation.prima_link !== undefined && quotation.prima_link !== null) {
      window.open(quotation.prima_link, '_self');
    }
  }, [loading, quotation]);

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(distanceToken, quotationId, documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(distanceToken, quotationId, documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const chooseSignMethod = async (signMethod, digitalSignatureEmail) => {
    const response = await selectSignMethod(distanceToken, quotationId, signMethod, digitalSignatureEmail);

    if (response.status === 200) {
      collectQuoteData();
    }
  };

  const resendInvalidSignedDocs = () => {
    autoResendInvalidSignedDocs(distanceToken, quotationId, collectQuoteData);
  };

  const remindClientToSign = () => {
    autoReminderToSignDocs(distanceToken, quotationId, collectQuoteData);
  };

  const getDocumentOfType = (documents, documentType) => documents.find((doc) => doc.document_type_id === documentType);

  const isDocumentNotSigned = (doc) => (doc.signable_document === 1 && doc.is_uploaded_valid === 1 && (doc.is_signed_valid === 0 || doc.is_signed_valid === null))
    && doc.signed_document_filename === null;

  const uploadSignedDocument = (documentId, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    if (file.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = async () => {
      const response = await documentMethodManualUpload(distanceToken, quotationId, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      });

      if (response.status !== 200) {
        generalAlertError('Si è verificato un problema con il caricamento');
        return;
      }

      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadDocumentOfType = async (file, documentType) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = file.name;
      const fileType = file.type;
      baseURL = reader.result;
      const data = {
        document_type_id: documentType,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };

      const response = await uploadDocument(distanceToken, quotationId, data);

      if (response.status !== 200) {
        generalAlertError('Si è verificato un problema con il caricamento');
        return;
      }

      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadOptionalDocument = (file, optionalDocumentRequestId) => {
    let fileInfo;
    let baseURL = '';
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = file.name;
      const fileType = file.type;
      baseURL = reader.result;
      const data = {
        optional_document_request_id: optionalDocumentRequestId,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };

      const response = await apiUploadOptionalDocument(distanceToken, quotationId, data);

      if (response.status !== 200) {
        return;
      }

      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    if (file.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = async () => {
      try {
        const response = await apiOptionalDocumentMethodManualUpload(distanceToken, quotationId, documentId, {
          document: reader.result.replace('data:application/pdf;base64,', ''),
        });

        if (response.status !== 200) {
          return;
        }

        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      } catch (err) {
        // console.error(err);
      }
    };
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(distanceToken, quotationId, documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const openOptionalDocumentModal = () => {
    setShowOptionalDocumentModal(true);
  };

  const removeOptionalDocument = async (documentId) => {
    try {
      const response = await apiDeleteOptionalDocument(distanceToken, quotationId, documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const getUserHasAgreedOnQuestionnarie = () => {
    if (insuranceRequest.questionnarie === null) {
      return false;
    }

    const agreedPreventiveQuotations = JSON.parse(insuranceRequest.questionnarie.agreed_on_preventives);

    if (!agreedPreventiveQuotations.includes(quotation.id)) {
      return false;
    }

    return true;
  };

  const acceptSelectedForBackoffice = () => {
    confirmSelectedForBackofficeQuote(quotation.id, collectQuoteData);
  };

  useEffect(() => {
    if (quotation === null) return;
    if (quotation.should_redirect === 1) {
      navigate(`/request/${distanceToken}/auto/results`);
      generalAlertError("Se vuoi selezionare un altro preventivo devi contattare con l'intermediario!");
    } else if (quotation.preventivas_company_id !== null && quotation.added_manually !== 1 && quotation.selected_for_backoffice === null) {
      confirmAlertForBackofficeBrokerQuote('Se confermi di procedere con questa quota e cambi idea devi fare modifica dati!', () => acceptSelectedForBackoffice(), navigate, distanceToken);
    }
  }, [JSON.stringify(quotation)]);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <div className="alert alert-danger m-5">{error}</div>;
  }

  const signableDocuments = [
    ...insuranceRequest.documents.filter((d) => d.signable_document === 1),
    ...quotation.documents.filter((d) => d.signable_document === 1),
    ...insuranceRequest.document_requests.map((v) => v.document).filter((filterDoc) => filterDoc !== null && filterDoc.signable_document === 1),
    ...quotation.document_requests.map((v) => v.document).filter((filterDoc) => filterDoc !== null && filterDoc.signable_document === 1),
  ];

  const unsignableDocuments = [
    ...insuranceRequest.documents.filter((d) => d.signable_document === 0),
    ...quotation.documents.filter((d) => d.signable_document === 0),
    ...insuranceRequest.document_requests.map((v) => v.document).filter((filterDoc) => filterDoc !== null && filterDoc.signable_document === 0),
    ...quotation.document_requests.map((v) => v.document).filter((filterDoc) => filterDoc !== null && filterDoc.signable_document === 0),
  ];

  const documentsRequestsThatNeedUpload = [
    ...insuranceRequest.document_requests.filter((dr) => (dr.document !== null && dr.document.signable_document === 0) || dr.document === null),
    ...quotation.document_requests.filter((dr) => (dr.document !== null && dr.document.signable_document === 0) || dr.document === null),
  ];

  const documentRequestsThatNeedSignature = [
    ...insuranceRequest.document_requests.filter((dr) => dr.document !== null && dr.document.signable_document === 1),
    ...quotation.document_requests.filter((dr) => dr.document !== null && dr.document.signable_document === 1),
  ];

  const areAllDocumentsSigned = signableDocuments.every((doc) => doc.signed_document_filename !== null)
    && documentRequestsThatNeedSignature.every((dr) => dr.document.signed_document_filename !== null);

  let unsignedDocumentsLength = 2 + documentsRequestsThatNeedUpload.length;
  if (['S', 'A'].includes(insuranceRequest.vehicle.inherit_merit_class) === true) {
    unsignedDocumentsLength++;
  }
  if (insuranceRequest.vehicle.inherit_merit_class === 'A') {
    unsignedDocumentsLength += 2;
  }
  if (insuranceRequest.customer.gender === 'G') {
    unsignedDocumentsLength++;
  }

  const areAllDocumentsUploaded = unsignableDocuments.length === unsignedDocumentsLength
    && unsignableDocuments.every((doc) => doc.is_uploaded_valid !== 0);

  let signatureType = null;

  if (quotation.documents.find((doc) => doc.document_type_id === 13) !== undefined) {
    signatureType = quotation.ending_signature_type;
  } else {
    signatureType = quotation.initial_signature_type;
  }

  const quoteStatus = quotation.status;

  const userHasAgreedOnQuestionnarie = getUserHasAgreedOnQuestionnarie();

  return (
    <PurchaseScreenStyles>
      <Header />
      <Container>
        <Title>Processo di aquisto auto</Title>
        <Stepper quoteStatus={quoteStatus} />
        {quoteStatus === 4
          && (
            <PaymentContainer
              distanceToken={distanceToken}
              quotationId={quotationId}
              quotation={quotation}
              collectQuoteData={collectQuoteData}
            />
          )}
        {userHasAgreedOnQuestionnarie === false && [1, 2, 3, 5].includes(quoteStatus)
          && (
            <Questionnarie
              distanceToken={distanceToken}
              quotationId={quotation.id}
              collectQuoteData={collectQuoteData}
              product="auto"
              steps={quotation.preventivas_company_id !== null ? [1, 2, 4] : [1, 2, 3, 4]}
              data={insuranceRequest.questionnarie !== null ? {
                ...insuranceRequest.questionnarie,
                email: insuranceRequest.signature_mail,
              } : null}
              insuranceRequestDocuments={insuranceRequest.documents}
            />
          )}
        {userHasAgreedOnQuestionnarie === true && [1, 2, 3, 5].includes(quoteStatus)
          && (
            <FlowContentSection>
              {!areAllDocumentsSigned
                ? <AlertBox type="success" text="ADESSO FIRMA I SEGUENTI DOCUMENTI." />
                : <div className="header">Documenti da firmare</div>}
              <div className="row">
                <div className="col-12 col-xl-7">
                  <DocumentsSignCard
                    signatureType={signatureType}
                    emailSelected={insuranceRequest.customer.email}
                    chooseSignMethod={chooseSignMethod}
                    quotationDocuments={quotation.documents}
                    insuranceRequestDocuments={insuranceRequest.documents}
                    resendInvalidSignedDocs={resendInvalidSignedDocs}
                    remindClientToSign={remindClientToSign}
                  />
                  <div className="d-flex flex-wrap justify-content-center p-3">
                    {(quoteStatus === 2 || quoteStatus === 1) && signatureType === 0
                      && (
                        <>
                          {getDocumentOfType(insuranceRequest.documents, 12) === undefined ? '' : isDocumentNotSigned(getDocumentOfType(insuranceRequest.documents, 12))
                            ? (
                              <Uploader
                                note="CARICA PRIVACY POLICY FIRMATA"
                                onFileUpload={(file) => uploadSignedDocument(getDocumentOfType(insuranceRequest.documents, 12).id, file)}
                              />
                            )
                            : (
                              <div className="alert alert-success alert-document">
                                La privacy policy firmata e stata caricata. Se il
                                documento non e valido elimina il documento e
                                ricarica. Grazie!
                              </div>
                            )}

                          {getDocumentOfType(insuranceRequest.documents, 28) === undefined ? '' : isDocumentNotSigned(getDocumentOfType(insuranceRequest.documents, 28))
                            ? (
                              <Uploader
                                note="CARICA DICHIARAZIONI DEL CONTRAENTE FIRMATA"
                                onFileUpload={(file) => uploadSignedDocument(getDocumentOfType(insuranceRequest.documents, 28).id, file)}
                              />
                            )
                            : (
                              <div className="alert alert-success alert-document">
                                Le dichiarazioni del contraente firmato e stato caricato. Se il
                                documento non e valido elimina il documento e
                                ricarica. Grazie!
                              </div>
                            )}

                          {getDocumentOfType(insuranceRequest.documents, 29) === undefined ? '' : (isDocumentNotSigned(getDocumentOfType(insuranceRequest.documents, 29)))
                            ? (
                              <Uploader
                                note="CARICA PRECONTRATTUALE GREENIA FIRMATO"
                                onFileUpload={(file) => uploadSignedDocument(getDocumentOfType(insuranceRequest.documents, 29).id, file)}
                              />
                            )
                            : (
                              <div className="alert alert-success alert-document">
                                Il precontrattuale greenia firmato e stato caricato. Se il
                                documento non e valido elimina il documento e
                                ricarica. Grazie!
                              </div>
                            )}

                          {getDocumentOfType(insuranceRequest.documents, 30) === undefined ? '' : (isDocumentNotSigned(getDocumentOfType(insuranceRequest.documents, 30)))
                            ? (
                              <Uploader
                                note="CARICA QUESTIONARIO AUTO FIRMATO"
                                onFileUpload={(file) => uploadSignedDocument(getDocumentOfType(insuranceRequest.documents, 30).id, file)}
                              />
                            )
                            : (
                              <div className="alert alert-success alert-document">
                                Il questionario auto firmato e stato caricato. Se il
                                documento non e valido elimina il documento e
                                ricarica. Grazie!
                              </div>
                            )}
                          {getDocumentOfType(insuranceRequest.documents, 34) === undefined ? '' : (isDocumentNotSigned(getDocumentOfType(insuranceRequest.documents, 34)))
                            ? (
                              <Uploader
                                note="CARICA DOCUMENTO FIRMATO"
                                onFileUpload={(file) => uploadSignedDocument(getDocumentOfType(insuranceRequest.documents, 34).id, file)}
                              />
                            )
                            : (
                              <div className="alert alert-success alert-document">
                                Il documento firmato e stato caricato. Se il
                                documento non e valido elimina il documento e
                                ricarica. Grazie!
                              </div>
                            )}

                          {documentRequestsThatNeedSignature.filter((dr) => dr.document.signable_document === 1 && dr.document.is_uploaded_valid === 1).map((dr) => (((dr.document.is_signed_valid === 0 || dr.document.is_signed_valid === null) && dr.document.signed_document_filename === null)
                            ? (
                              <Uploader
                                note={`CARICA ${dr.optional_document_type.name.toUpperCase()} FIRMATO`}
                                onFileUpload={(file) => uploadSignedOptionalDocument(dr.document.id, file)}
                              />
                            )
                            : (
                              <div className="alert alert-success alert-document">
                                Il documento (
                                {dr.optional_document_type.name}
                                ) firmato e stato caricato. Se il
                                documento non e valido elimina il documento e
                                ricarica. Grazie!
                              </div>
                            )))}
                        </>
                      )}
                  </div>
                </div>
                <div className="col-xl-5 col-12">
                  <DocumentsUploaded
                    signable={1}
                    documents={signableDocuments}
                    downloadDocument={downloadDocument}
                    downloadOptionalDocument={downloadOptionalDocument}
                    removeDocument={removeDocument}
                    removeOptionalDocument={removeOptionalDocument}
                  />
                </div>
              </div>
              {!areAllDocumentsUploaded
                ? <AlertBox type="success" text="ADESSO CARICA I DOCUMENTI DEL CLIENTE." />
                : <div className="header">Documenti da caricare</div>}
              <div className="row">
                <div className="col-xl-7 col-12 d-flex flex-wrap justify-content-center p-3">
                  {(quoteStatus === 2 || quoteStatus === 1)
                    && (
                      <>
                        {getDocumentOfType(insuranceRequest.documents, 1) === undefined
                          ? (
                            <Uploader
                              note="CARICA CARTA D'IDENTITA"
                              onFileUpload={(file) => uploadDocumentOfType(file, 1)}
                            />
                          )
                          : (
                            <div className="alert alert-success alert-document">
                              La carta d'identita e stata caricata. Se il
                              documento non e valido elimina il documento e
                              ricarica. Grazie!
                            </div>
                          )}

                        {getDocumentOfType(insuranceRequest.documents, 2) === undefined
                          ? (
                            <Uploader
                              note="CARICA LIBRETTO DEL VEICOLO"
                              onFileUpload={(file) => uploadDocumentOfType(file, 2)}
                            />
                          )
                          : (
                            <div className="alert alert-success alert-document">
                              Il libretto del veicolo e stato caricato. Se il
                              documento non e valido elimina il documento e
                              ricarica. Grazie!
                            </div>
                          )}

                        {insuranceRequest.vehicle.insurance_type === 'N' && ['S', 'A'].includes(insuranceRequest.vehicle.inherit_merit_class) === true
                          && (
                            <>
                              {getDocumentOfType(insuranceRequest.documents, 20) === undefined
                                ? (
                                  <Uploader
                                    note="CARICA LIBRETTO VEICOLO PER BERSANI"
                                    onFileUpload={(file) => uploadDocumentOfType(file, 20)}
                                  />
                                )
                                : (
                                  <div className="alert alert-success alert-document">
                                    Il libretto veicolo per bersani e stato caricato. Se il
                                    documento non e valido elimina il documento e
                                    ricarica. Grazie!
                                  </div>
                                )}
                            </>
                          )}

                        {insuranceRequest.vehicle.insurance_type === 'N' && insuranceRequest.vehicle.inherit_merit_class === 'A'
                          && (
                            <>
                              {getDocumentOfType(insuranceRequest.documents, 21) === undefined
                                ? (
                                  <Uploader
                                    note="CARICA STATO DI FAMIGLIA"
                                    onFileUpload={(file) => uploadDocumentOfType(file, 21)}
                                  />
                                )
                                : (
                                  <div className="alert alert-success alert-document">
                                    Il stato di famiglia e stato caricato. Se il
                                    documento non e valido elimina il documento e
                                    ricarica. Grazie!
                                  </div>
                                )}

                              {getDocumentOfType(insuranceRequest.documents, 22) === undefined
                                ? (
                                  <Uploader
                                    note="CARICA CARTA D'IDENTITA FAMIGLIARE"
                                    onFileUpload={(file) => uploadDocumentOfType(file, 22)}
                                  />
                                )
                                : (
                                  <div className="alert alert-success alert-document">
                                    La carta d'identita famigliare e stata caricata. Se il
                                    documento non e valido elimina il documento e
                                    ricarica. Grazie!
                                  </div>
                                )}
                            </>
                          )}

                        {insuranceRequest.customer.gender === 'G'
                          && (
                            <>
                              {getDocumentOfType(insuranceRequest.documents, 24) === undefined
                                ? (
                                  <Uploader
                                    note="CARICA VISURA CAMERALE"
                                    onFileUpload={(file) => uploadDocumentOfType(file, 24)}
                                  />
                                )
                                : (
                                  <div className="alert alert-success alert-document">
                                    La visura camerale e stata caricata. Se il
                                    documento non e valido elimina il documento e
                                    ricarica. Grazie!
                                  </div>
                                )}
                            </>
                          )}

                        {documentsRequestsThatNeedUpload.map((dr) => (
                          <>
                            {dr.document === null
                              ? (
                                <Uploader
                                  note={`CARICA ${dr.optional_document_type.name.toUpperCase()}`}
                                  onFileUpload={(file) => uploadOptionalDocument(file, dr.id)}
                                />
                              )
                              : (
                                <div className="alert alert-success alert-document">
                                  Il documento (
                                  {dr.optional_document_type.name}
                                  ) e stato caricato. Se il
                                  documento non e valido elimina il documento e
                                  ricarica. Grazie!
                                </div>
                              )}
                          </>
                        ))}
                      </>
                    )}
                </div>
                <div className="col-xl-5 col-12">
                  <div className="btn btn-info text-white w-100 mb-2" onClick={() => openOptionalDocumentModal()}>Richiedi di aggiungere un documento</div>
                  {showOptionalDocumentModal === true
                    && (
                      <OptionalDocumentModal
                        distanceToken={distanceToken}
                        quotationId={quotation.id}
                        closeModal={() => setShowOptionalDocumentModal(false)}
                        collectQuoteData={collectQuoteData}
                      />
                    )}
                  <DocumentsUploaded
                    signable={0}
                    documents={unsignableDocuments}
                    downloadDocument={downloadDocument}
                    downloadOptionalDocument={downloadOptionalDocument}
                    removeDocument={removeDocument}
                    removeOptionalDocument={removeOptionalDocument}
                  />
                </div>
              </div>
            </FlowContentSection>
          )}

        {quoteStatus === 6
          && (
            <div
              className="alert alert-success mt-3"
              role="alert"
            >
              <h4 className="alert-heading">
                In Attesa Emissione
              </h4>
              <p>
                Quando l'agente di backoffice carica la
                polizza, riceverai una notifica e il
                contratto potrà essere scaricato.
              </p>
            </div>
          )}

        {quoteStatus === 7
          && (
            <div
              className="alert alert-success mt-3"
              role="alert"
            >
              <h4 className="alert-heading">
                Processo Completato
              </h4>
              <p>
                Hai concluso il processo di acquisto.
              </p>
            </div>
          )}

      </Container>
      <Footer />
    </PurchaseScreenStyles>
  );
}

export default AutoPurchaseScreen;
