import { BACKEND_BASE_URL } from '../../../../configs/urls';
import { Card } from './styles/common.styles';
import { guideTypes } from './utils/guide_types';

const getPaymentFrequency = (paymentFrequency) => {
  const pf = {
    1: 'Annuo',
    2: 'Semestrale',
  };

  return pf[paymentFrequency];
};

const coverageName = {
  incendio: 'Incendio',
  furto: 'Furto',
  eventi_naturali: 'Eventi Naturali',
  assistenza_stradale: 'Assistenza stradale',
  eventi_sociopolitici: 'Atti vandalici',
  cristalli: 'Cristalli',
  rivalsa: 'Rivalsa',
  infortuni_conducente: 'Infortuni conducente',
};

const coveragePrice = (quote, coverageSlug) => {
  const prices = {
    incendio: quote.fire_total_amount,
    furto: quote.theft_total_amount,
    eventi_naturali: quote.natural_events_total_amount,
    assistenza_stradale: quote.road_assistance_total_amount,
    eventi_sociopolitici: quote.social_political_events_total_amount,
  };
  return prices[coverageSlug];
};

const coverageNewPrice = (coverageSlug) => {
  const prices = {
    assistenza_stradale: 55,
    infortuni_conducente: 46,
    cristalli: 46,
    rivalsa: 30,
  };
  return prices[coverageSlug];
};

const coverageAmount = (quote, coverageSlug) => {
  if (quote.tax_total === null) {
    return Number(coveragePrice(quote.quotation_data_adriatic, coverageSlug)).toLocaleString('it-IT', {
      minimumFractionDigits: 2,
    });
  }

  return Number(coverageNewPrice(coverageSlug)).toLocaleString('it-IT', {
    minimumFractionDigits: 2,
  });
};

const guaranteesAdricatic = (quote) => {
  if (quote === null) {
    return [];
  }

  const guarantees = [];

  if (quote.fire == 1) {
    guarantees.push('incendio');
  }
  if (quote.theft == 1) {
    guarantees.push('furto');
  }
  if (quote.natural_events == 1) {
    guarantees.push('eventi_naturali');
  }
  if (quote.road_assistance == 1) {
    guarantees.push('assistenza_stradale');
  }
  if (quote.social_political_events == 1) {
    guarantees.push('eventi_sociopolitici');
  }

  return guarantees;
};

function AdriaticCard({
  insuranceRequest, quotation, coverages, aquista,
}) {
  const isAquista = quotation.is_aquista;
  const allowedGuaranteesAdricatic = guaranteesAdricatic(quotation.quotation_data_adriatic);
  const finalCoverages = isAquista
    ? quotation.guarantees.map((g) => g.guarantees_slug)
    : coverages;
  const checkIfHaveCoverages = () => {
    let includes = true;
    coverages.forEach((coverage) => {
      if (allowedGuaranteesAdricatic.indexOf(coverage) === -1) {
        includes = false;
      }
    });
    return includes;
  };

  const allowedGuranteesAdriaticNew = [
    'assistenza_stradale',
    'infortuni_conducente',
    'cristalli',
    'rivalsa',
  ];

  const checkIfHaveCoveragesNew = () => {
    let includes = true;
    coverages.forEach((coverage) => {
      if (allowedGuranteesAdriaticNew.indexOf(coverage) === -1) {
        includes = false;
      }
    });
    return includes;
  };

  const getTotalOfCoveragesNew = () => allowedGuranteesAdriaticNew
    .filter((adr) => {
      for (let i = 0; i < finalCoverages.length; i++) {
        if (adr === finalCoverages[i]) return true;
      }
      return false;
    }).reduce((prev, curr) => {
      if (prev === null) return coverageNewPrice(curr);

      return prev + coverageNewPrice(curr);
    }, null);

  const hasCoverages = quotation.tax_total !== null && coverages.length !== 0
    ? checkIfHaveCoveragesNew()
    : checkIfHaveCoverages();

  return (
    <Card isAquista={quotation.is_aquista}>
      <img className="company-logo" src={BACKEND_BASE_URL + quotation.company.logo} />
      <div className="information">
        <div className="information-row">
          <div className="information-label">Compagnia:</div>
          <div className="information-value">Adriatic</div>
        </div>
        <div className="information-row">
          <div className="information-label">Risarcimento:</div>
          <div className="information-value">Rc con Risarcimento Diretto e RFS</div>
        </div>
        <div className="information-row">
          <div className="information-label">Emissione:</div>
          <div className="information-value">Tradizionale</div>
        </div>
        <div className="information-row">
          <div className="information-label">Massimale:</div>
          <div className="information-value">
            6'450'000€ Danni a persone
            {' '}
            <br />
            1'050'000€ Danni a cose
          </div>
        </div>
        <div className="information-row">
          <div className="information-label">Guida:</div>
          <div className="information-value">{guideTypes.find((v) => v.id === insuranceRequest.guide_type).name}</div>
        </div>
        <div className="information-row">
          <div className="information-label">Frequenza di pagamento:</div>
          <div className="information-value">
            <div className="badge badge-info">{getPaymentFrequency(quotation.payment_frequency)}</div>
          </div>
        </div>
        <div className="information-row">
          <div className="information-label">Garanzie Incluse:</div>
          <div className="information-value">
            <div className="information-guarantee">
              <span>Responsabilità civile:</span>
              <span>
                €
                {' '}
                {Number((quotation.tax_total !== null)
                  ? quotation.amount - getTotalOfCoveragesNew()
                  : quotation.quotation_data_adriatic.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
              </span>
            </div>
            {quotation.tax_total === null
                        && (
                        <>
                          {allowedGuaranteesAdricatic.filter((adr) => {
                            for (let i = 0; i < finalCoverages.length; i++) {
                              if (adr === finalCoverages[i]) return true;
                            }
                            return false;
                          })
                            .map((guarantee, index) => (
                              <div className="information-guarantee" key={index}>
                                <span>{coverageName[guarantee]}</span>
                                <span>
                                  €
                                  {coverageAmount(quotation, guarantee)}
                                </span>
                              </div>
                            ))}
                        </>
                        )}
            {quotation.tax_total !== null
                        && (
                        <>
                          {allowedGuranteesAdriaticNew.filter((adr) => {
                            for (let i = 0; i < finalCoverages.length; i++) {
                              if (adr === finalCoverages[i]) return true;
                            }
                            return false;
                          })
                            .map((guarantee, index) => (
                              <div className="information-guarantee" key={index}>
                                <span>{coverageName[guarantee]}</span>
                                <span>
                                  €
                                  {coverageNewPrice(guarantee)}
                                </span>
                              </div>
                            ))}
                        </>
                        )}

          </div>
        </div>
      </div>
      <div className="pricing">
        {hasCoverages === false
                && <div>Adriatic non incude questa garanzia!</div>}
        {hasCoverages === true
                && (
                <>
                  <div className="pricing-label">Prezzo Annuo</div>
                  {quotation.amount_before_discount !== null && quotation.amount_before_discount !== quotation.amount
                        && (
                        <div className="pricing-discount">
                          €
                          {' '}
                          {Number(
                            quotation.amount_before_discount + getTotalOfCoveragesNew(),
                          ).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                        )}
                  <div className="pricing-amount">
                    €
                    {' '}
                    {Number(
                      quotation.amount,
                    ).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                  {quotation.payment_frequency === 2
                    && (
                    <>
                      <div className="title">Prezzo semestrale:</div>
                      <div className="amount">
                        &euro;
                        {Number(quotation.amount / 2).toLocaleString('it-IT', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </>
                    )}
                  <div className="aquista-button" onClick={() => aquista()}>
                    Acquista
                  </div>
                </>
                )}
      </div>
    </Card>
  );
}

export default AdriaticCard;
