import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import { InputText } from '../../../formInputs/InputText';
import AlertBox from '../../AlertBox';
import { QuestionarieStyled } from './Questionnarie.styles';
import { backAxe } from '../../../../services/axios-client/backAxeClient';
import { generalAlertSuccess } from '../../../../utils/alert';
import { apiDownloadDocument, greeniaPrivacyPolicy } from '../../../../services/axios-client/axeCommons';
import { downloadFile } from '../../../../services/axios-client/axeHelpers';

const mustSelectYesErrorMessage = 'Seleziona Si per procedere! Se hai domande chiedi al intermediario!';

export function Questionnarie({
  distanceToken, quotationId, steps, data, collectQuoteData, product, insuranceRequestDocuments,
}) {
  const validationRules = {};

  if (steps.includes(1)) {
    validationRules.agreed_on_documents = yup.boolean().required('Il campo è obbligatorio.').oneOf([true], mustSelectYesErrorMessage);
  }

  if (steps.includes(2)) {
    validationRules.agreed_on_email = yup.boolean().required('Il campo è obbligatorio.').oneOf([true], mustSelectYesErrorMessage);
    validationRules.email = yup.string().required('Il campo è obbligatorio.').email('il campo deve essere un email');
  }

  if (steps.includes(3)) {
    validationRules.agreed_on_preventives = yup.boolean().required('Il campo è obbligatorio.').oneOf([true], mustSelectYesErrorMessage);
  }

  if (steps.includes(4)) {
    validationRules.agreed_on_quotes = yup.boolean().required('Il campo è obbligatorio.').oneOf([true], mustSelectYesErrorMessage);
  }

  const schema = yup.object(validationRules);

  const defaultValues = {
    product,
  };

  if (steps.includes(1)) {
    defaultValues.agreed_on_documents = null;

    if (data !== null) {
      defaultValues.agreed_on_documents = !!data.agreed_on_documents;
    }
  }

  if (steps.includes(2)) {
    defaultValues.agreed_on_email = null;
    defaultValues.email = '';

    if (data !== null) {
      defaultValues.agreed_on_email = !!data.agreed_on_email;
      defaultValues.email = data.email;
    }
  }

  if (steps.includes(3)) {
    defaultValues.agreed_on_preventives = null;

    if (data !== null) {
      const acceptedQuotationIds = JSON.parse(data.agreed_on_preventives);
      if (acceptedQuotationIds.includes(quotationId)) {
        defaultValues.agreed_on_preventives = true;
      }
    }
  }

  if (steps.includes(4)) {
    defaultValues.agreed_on_quotes = null;

    if (data !== null) {
      defaultValues.agreed_on_quotes = !!data.agreed_on_quotes;
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues,
  });

  const [loading, setLoading] = useState(false);

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const selectAgreement = (name, value) => {
    setValue(name, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const sendData = async (data) => {
    try {
      setLoading(true);
      const response = await backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/submit-questionnario`, data);

      setLoading(false);

      if (response.status === 200) {
        generalAlertSuccess('Ora puoi continuare con il processo di acquisto');
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const downloadPreventive = async () => {
    const res = await backAxe.get(`/request/${distanceToken}/quotations/${quotationId}/preventivo/generate`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(distanceToken, quotationId, documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const formData = watch();

  const precontrattualeGreenia = insuranceRequestDocuments.find((doc) => doc.document_type_id === 29);

  return (
    <QuestionarieStyled>
      <br />
      <AlertBox type="success" text="Questionario pre assuntivo" />
      <form className="questionnarie_agreements" onSubmit={handleSubmit(sendData)}>
        {steps.includes(1) === true
                && (
                <>
                  <div className="questionnarie_agreement">
                    <div className="agreement_contents">
                      <div className="agreement_text">
                        Confermo di aver preso visione ei seguenti documenti e di averne capito il contenuto
                        {' '}
                        <br />
                        <div className="link" onClick={() => greeniaPrivacyPolicy()}>Privacy Greenia.pdf</div>
                        {precontrattualeGreenia !== undefined
                                    && <div className="link" onClick={() => downloadDocument(precontrattualeGreenia.id)}>Precontrattuale Greenia.pdf</div>}
                      </div>
                      <div className="agreement_acceptance">
                        <div className={`acceptance_button ${formData.agreed_on_documents === true ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_documents', true)}>Si</div>
                        <div className={`acceptance_button ${formData.agreed_on_documents === false ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_documents', false)}>No</div>
                      </div>
                    </div>
                    {errors.agreed_on_documents?.message !== undefined
                            && (
                            <div className="questionnarie_agreement_errors">
                              <div className="agreement_error">
                                {errors.agreed_on_documents.message}
                              </div>
                            </div>
                            )}
                  </div>
                </>
                )}

        {steps.includes(2) === true
                && (
                <div className="questionnarie_agreement">
                  <div className="agreement_contents">
                    <div className="agreement_text">
                      Confermo di voler ricevere la documentazione contrattuale mezzo mail all'indirizzo
                      {' '}
                      <br />
                      <InputText
                        placeholder="Email"
                        registration={register('email')}
                        error={errors.email?.message}
                        valid={isValid('email')}
                      />
                    </div>
                    <div className="agreement_acceptance">
                      <div className={`acceptance_button ${formData.agreed_on_email === true ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_email', true)}>Si</div>
                      <div className={`acceptance_button ${formData.agreed_on_email === false ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_email', false)}>No</div>
                    </div>
                  </div>
                  {(errors.agreed_on_email?.message !== undefined)
                        && (
                        <div className="questionnarie_agreement_errors">
                          <div className="agreement_error">
                            {errors.agreed_on_email.message}
                          </div>
                        </div>
                        )}
                </div>
                )}

        {steps.includes(3) === true
                && (
                <div className="questionnarie_agreement">
                  <div className="agreement_contents">
                    <div className="agreement_text">
                      Confermo che il mio bisogno assicurativo e stato correttamente indentificato
                      {' '}
                      <br />
                      <div className="link" onClick={() => downloadPreventive()}>Preventivo.pdf</div>
                    </div>
                    <div className="agreement_acceptance">
                      <div className={`acceptance_button ${formData.agreed_on_preventives === true ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_preventives', true)}>Si</div>
                      <div className={`acceptance_button ${formData.agreed_on_preventives === false ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_preventives', false)}>No</div>
                    </div>
                  </div>
                  {errors.agreed_on_preventives?.message !== undefined
                        && (
                        <div className="questionnarie_agreement_errors">
                          <div className="agreement_error">
                            {errors.agreed_on_preventives.message}
                          </div>
                        </div>
                        )}
                </div>
                )}

        {steps.includes(4) === true
                && (
                <div className="questionnarie_agreement">
                  <div className="agreement_contents">
                    <div className="agreement_text">
                      Confermo di aver preso visione delle quote di mercato soddisfatte da greenia srl
                    </div>
                    <div className="agreement_acceptance">
                      <div className={`acceptance_button ${formData.agreed_on_quotes === true ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_quotes', true)}>Si</div>
                      <div className={`acceptance_button ${formData.agreed_on_quotes === false ? 'selected' : ''}`} onClick={() => selectAgreement('agreed_on_quotes', false)}>No</div>
                    </div>
                  </div>
                  {errors.agreed_on_quotes?.message !== undefined
                        && (
                        <div className="questionnarie_agreement_errors">
                          <div className="agreement_error">
                            {errors.agreed_on_quotes.message}
                          </div>
                        </div>
                        )}
                </div>
                )}

        <button disabled={loading} className="submit-button d-flex align-items-center justify-content-center" type="submit">
          {loading === true
                    && (
                    <>
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only" />
                      </div>
                    </>
                    )}
          {loading === false
                    && <>Conferma</>}
        </button>
      </form>
    </QuestionarieStyled>
  );
}
