import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_BASE_URL } from '../../../../configs/urls';
import { Card } from '../vehicles/styles/common.styles';
import { updateTravelGuarantee } from '../../../../features/results/resultsActions';

function TravelCard({ quotation, aquista, distanceToken }) {
  const updateGuarantees = (quote_id, coverage_id) => {
    updateTravelGuarantee(quote_id, coverage_id, distanceToken);
  };

  return (
    <Card isAquista={quotation.is_aquista} className="flex-column">
      <div className="d-flex align-items-center justify-content-around">
        <img className="company-logo" src={BACKEND_BASE_URL + quotation.company.logo} />
        <div className="information">
          <h3>Informazioni Prodotto</h3>
          <hr />

          <div className="information-row">
            <div className="information-label">Nome Prodotto:</div>
            <div className="information-value">{quotation.quotation_data_travel_europeassistance.product_name}</div>
          </div>
          {quotation.quotation_data_travel_europeassistance.coverages.map((coverage, index) => (
            <>
              {coverage.enabled === 1
                && (
                <div className="information-row" key={index}>
                  <div className="information-label">
                    {coverage.title}
                    :
                  </div>
                  <div className="information-value">{coverage.limit_desc}</div>
                </div>
                )}
              {
                quotation.quotation_coverages.map((cov, index) => (
                  coverage.coverage_id === cov.guarantees_slug
                  && (
                  <div className="qinfo" key={index}>
                    <span>
                      <b>
                        {coverage.title}
                        :
                      </b>
                    </span>
                    <span>{coverage.limit_desc}</span>
                  </div>
                  )
                ))
              }
            </>
          ))}
        </div>
        <div className="pricing">
          <div className="pricing-label">Prezzo Annuo</div>
          <div className="pricing-amount">
            €
            {' '}
            {Number(
              quotation.amount,
            ).toLocaleString('it-IT', {
              minimumFractionDigits: 2,
            })}
          </div>
          <div className="aquista-button" onClick={() => aquista()}>
            Acquista
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-9 px-3">
          <div className="row my-2">
            {quotation.quotation_data_travel_europeassistance.coverages.filter((i) => i.enabled === 0).map((coverage, index) => (
              <div className="col-4 px-1" key={index}>
                <div className={`border p-2 rounded-2 border-${quotation.quotation_coverages.find((item) => item.guarantees_slug === coverage.coverage_id) ? 'danger' : 'success'} d-flex align-items-center justify-content-between gap-1 my-1`} style={{ cursor: 'pointer' }} onClick={quotation.is_aquista === 1 ? () => { } : () => updateGuarantees(quotation.id, coverage.coverage_id)}>
                  <FontAwesomeIcon icon={quotation.quotation_coverages.find((item) => item.guarantees_slug === coverage.coverage_id) ? faTimesCircle : faPlusCircle} className={`text-${quotation.quotation_coverages.find((item) => item.guarantees_slug === coverage.coverage_id) ? 'danger' : 'success'}`} />
                  {' '}
                  <div className={`text-center text-${quotation.quotation_coverages.find((item) => item.guarantees_slug === coverage.coverage_id) ? 'danger' : 'success'}`} style={{ fontSize: '12px' }}>{coverage.title}</div>
                  {' '}
                  <div className={`text-${quotation.quotation_coverages.find((item) => item.guarantees_slug === coverage.coverage_id) ? 'danger' : 'success'}`} style={{ fontSize: '12px' }}>
                    &euro;
                    {parseFloat(coverage.coverage_amount_gros).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TravelCard;
