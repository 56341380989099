import styled from 'styled-components';

export const PaymentContainerStyles = styled.div`
    margin-top: 20px;

    .custom-row {
        flex-wrap: wrap;
    }

    .custom-card {
        width: 100%;
        padding: 20px;
        min-width: 300px;
    }

    .col-md-6 {
        margin-bottom: 0;
    }

    .form-group > label {
        font-weight: bold;
        margin-bottom: 5px;
    }

    .form-group > .form-control {
        padding: 10px 10px;
        border: 2px solid black;
    }

    .form-group > .error {
        color: rgb(255,50,50);
    }

    .alert-error {
        padding: 10px 15px;
        color: rgb(255,50,50);
        background-color: rgb(255,200,200);
        border: 1px solid rgb(255,50,50);
    }

    .confirm-button {
        margin-top: 30px;
        padding: 15px 20px;
        width: 100%;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        border: none;
        outline: none;
        background-color: rgb(0, 165, 79);
        color: white;
    }

    .invoice {
        padding: 10px;
    }

    .customer-information {
        margin-bottom: 30px;
    }

    .company-info {
        padding: 10px 20px;
    }

    .quotation-company-logo {
        width: 150px;
        height: 80px;
        object-fit: contain;
    }

    .field-row {
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        border-bottom: 1px solid grey;
    }

    .field-row:last-child {
        border-bottom: none;
    }

    .field-name {
        flex: 1;
    }

    .invoice-totals .field-name {
        flex: 1;
        min-width: unset;
    }

    .field-value {
        flex: 1;
    }

    @media (max-width: 600px) {
        .field-row {
            flex-direction: column;
        }
    }

    .field-value.pull-right {
        text-align: right;
    }

    .invoice-items {
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;
    }

    .invoice-totals {
        margin-top: 30px;
        font-size: 25px;
    }

    .payment-record {
        margin-bottom: 10px;
        padding: 10px;
        max-width: 600px;
        border: 1px solid grey;
    }
`;

export const PaymentProofStyled = styled.div`
    margin: 10px auto;
    padding: 20px;
    border: 1px solid grey;
`;
