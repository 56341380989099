import styled from 'styled-components';

export const LogoHeader = styled.div`
  padding: 10px 0;
  width: 100%;
  background-color: #00a551;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    img {
      height: 50px;
    }
  }

  @media (max-width: 800px) {
    a {
      img {
        height: 40px;
      }
    }
  }
`;
