import styled from 'styled-components';

export const DocumentsUploadedContainer = styled.div`
    width: 100%;
    min-height: 200px;
    border: 1px solid grey;
    border-radius: 10px;

    .title {
        color: grey;
        padding: 3px 15px;
        border-bottom: 1px solid grey;
        text-align: center;
    }

    .no-results {
        padding: 15px;
        text-align: center;
    }
`;

export const DocumentRowContainer = styled.div`
    padding: 5px;
    border-bottom: 1px solid grey;

    &:last-child {
        border-bottom: none;
    }

    .main {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        display: flex;
        align-items: center;
    }

    .status-badge {
        margin: 3px;
        width: 30px;
        height: 30px;
    }

    .actions {
        display: flex;
    }

    .button {
        margin: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid grey;
        color: grey;
        border-radius: 3px;
        cursor: pointer;
        transition: .3s;
    }

    .button:hover {
        background-color: rgba(0,0,0, .1);
    }

    .delete-button {
        border: 1px solid var(--danger-color);
        color: var(--danger-color);
    }

    .delete-button:hover {
        background-color: rgba(220, 53, 69, .1);
    }

    .motivation {
        padding: 8px;
        background-color: lightcoral;
        color: white;
        font-size: 14px;
        border-radius: 3px;
    }

    .status-label {
        margin: 5px;
        padding: 3px 10px;
        color: black;
        font-weight: bold;
        font-size: 13px;
        border-radius: 5px;
    }

    .status-label-warning {
        background-color: rgb(255,193,7);
    }

    .status-label-danger {
        background-color: var(--danger-color);
    }

    .status-label-success {
        background-color: var(--greenia-color);
    }

    .filename {
        padding: 5px 10px;
    }

    @media (max-width: 600px) {
        .main {
            flex-direction: column;
        }
    }
`;
