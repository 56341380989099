import { BACKEND_BASE_URL } from '../../../../configs/urls';
import { Card } from './styles/common.styles';

function InfodriveCard({ insuranceRequest, quotation, aquista }) {
  return (
    <Card isAquista={quotation.is_aquista}>
      <img className="company-logo" src={BACKEND_BASE_URL + quotation.company.logo} />
      <div className="information">
        <h3>Informazioni Prodotto</h3>
        <hr />

        <div className="information-row">
          <div className="information-label">Nome Prodotto:</div>
          <div className="information-value">{insuranceRequest.infodrive.product_name}</div>
        </div>
        <div className="information-row">
          <div className="information-label">Durata:</div>
          <div className="information-value">
            {insuranceRequest.infodrive.duration_in_months}
            {' '}
            Mesi
          </div>
        </div>
        <div className="information-row">
          <div className="information-label">Versione Prodotto:</div>
          <div className="information-value">{insuranceRequest.infodrive.version}</div>
        </div>
      </div>
      <div className="pricing">
        <div className="pricing-label">Prezzo Annuo</div>
        <div className="pricing-amount">
          €
          {' '}
          {Number(
            quotation.amount,
          ).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
          })}
          <div className="sub">iva inclusa</div>
        </div>
        <div className="aquista-button" onClick={() => aquista()}>
          Acquista
        </div>
      </div>
    </Card>
  );
}

export default InfodriveCard;
