import { useState } from 'react';

import { DocumentsSignCardContainer, DocumentsSignCardContainerDivider, DocumentsSignCardContainerRow } from './DocumentsSignCard.styles';

export function DocumentsSignCard({
  signatureType,
  emailSelected,
  chooseSignMethod,
  quotationDocuments,
  insuranceRequestDocuments,
  resendInvalidSignedDocs,
  remindClientToSign,
}) {
  const [anotherEmail, setAnotherEmail] = useState('');

  return (
    <>
      <DocumentsSignCardContainer>
        <DocumentsSignCardContainerRow>
          <div className="label">Stato del preventivo</div>
          <div className="value">
            <div className="badge badge-success">Attesa Documenti</div>
          </div>
        </DocumentsSignCardContainerRow>
        <DocumentsSignCardContainerDivider />
        <DocumentsSignCardContainerRow>
          <div className="label">La data di creazione</div>
          <div className="value">
            <div className="badge badge-success">11/9/2023</div>
          </div>
        </DocumentsSignCardContainerRow>
        <DocumentsSignCardContainerDivider />
        <DocumentsSignCardContainerRow>
          <div className="label">La data inizio rischi</div>
          <div className="value">
            <div className="badge badge-success">21/9/2023</div>
          </div>
        </DocumentsSignCardContainerRow>
        <DocumentsSignCardContainerDivider />
        <DocumentsSignCardContainerRow>
          <div className="label">
            {signatureType === null ? 'Scegli il tuo metodo di firma' : 'Metodo di firma: '}
          </div>
          <div className="value">
            {signatureType === null
                        && (
                        <>
                          <div className="button-outline button-outline-success" data-bs-toggle="modal" data-bs-target="#emailPopup">Firma Digitale</div>
                          <div className="button-outline button-outline-dark" onClick={() => chooseSignMethod(0)}>Cartacea</div>
                        </>
                        )}
            {signatureType === 1
                        && (
                        <>
                          {quotationDocuments.find((doc) => doc.is_signed_valid === 0) || insuranceRequestDocuments.find((doc) => doc.is_signed_valid === 0)
                            ? (
                              <>
                                <div className="badge bg-dark">In uno o più documenti la firma non è valida</div>
                                <button className="btn btn-outline-success" onClick={() => resendInvalidSignedDocs()}>
                                  Inviare per rifirmare
                                </button>
                              </>
                            )
                            : (
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="badge bg-dark me-2">E stata inviata un'e-mail al cliente!</div>
                                <button className="btn btn-outline-success" onClick={() => remindClientToSign()}>
                                  Ricorda di firmare
                                </button>
                              </div>
                            )}
                        </>
                        )}
            {signatureType === 0
                        && <>Cartacea</>}
          </div>
        </DocumentsSignCardContainerRow>
      </DocumentsSignCardContainer>
      <div className="modal" role="dialog" id="emailPopup">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Quale indirizzo email vuoi usare per la firma?
              </h5>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0 w-75">
                  La mail inserita:
                  {' '}
                  {emailSelected}
                </p>
                <button type="button" className="btn btn-outline-success w-25" data-bs-dismiss="modal" onClick={() => chooseSignMethod(1)}>Usa</button>
              </div>
              <div className="w-100 mt-4">
                <div className="d-flex align-items-center justify-content-between gap-2">
                  <input type="text" className="form-control mb-0 w-75" onChange={(e) => setAnotherEmail(e.target.value)} value={anotherEmail} placeholder="Inserisci un altra mail..." />
                  <button type="button" className="btn btn-outline-success w-25" data-bs-dismiss="modal" onClick={() => chooseSignMethod(1, anotherEmail)}>Invia</button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
