import { InputSelectStyles } from './InputSelect.styles';

export function InputSelect({
  label, registration, error, valid, placeholder, options,
}) {
  return (
    <InputSelectStyles>
      <div className="form-group">
        <label className="label">{label}</label>
        <select className={`form-control ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} {...registration}>
          <option value="" disabled>{placeholder}</option>
          {options.map((option) => <option value={option.id.toString()}>{option.name}</option>)}
        </select>
        <div className="invalid-feedback">
          {error}
        </div>
      </div>
    </InputSelectStyles>
  );
}
