import { InputTextStyled } from './InputText.styles';

export function InputText({
  label, registration, error, valid, placeholder,
}) {
  return (
    <InputTextStyled>
      <div className="form-group">
        {label !== undefined
                && <label className="label">{label}</label>}
        <input type="text" className={`form-control ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} placeholder={placeholder} {...registration} />
        <div className="invalid-feedback">
          {error}
        </div>
      </div>
    </InputTextStyled>
  );
}
