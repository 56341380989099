import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import './App.css';

import { HomePageRedirect } from './screens/HomePageRedirect/HomePageRedirect';
import AutoResultsScreen from './screens/results/AutoResultsScreen/AutoResultsScreen';
import AutoPurchaseScreen from './screens/purchases/AutoPurchaseScreen/AutoPurchaseScreen';
import AutocarroResultsScreen from './screens/results/AutocarroResultsScreen/AutocarroResultsScreen';
import AutocarroPurchaseScreen from './screens/purchases/AutocarroPurchaseScreen/AutocarroPurchaseScreen';
import InfortuniResultsScreen from './screens/results/InfortuniResultsScreen/InfortuniResultsScreen';
import InfortuniPurchaseScreen from './screens/purchases/InfortuniPurchaseScreen/InfortuniPurchaseScreen';
import ProfessionaleResultsScreen from './screens/results/ProfessionaleResultsScreen/ProfessionaleResultsScreen';
import ProfessionalePurchaseScreen from './screens/purchases/ProfessionalePurchaseScreen/ProfessionalePurchaseScreen';
import InfodriveResultsScreen from './screens/results/InfodriveResultsScreen/InfodriveResultsScreen';
import InfodrivePurchaseScreen from './screens/purchases/InfodrivePurchaseScreen/InfodrivePurchaseScreen';
import TravelResultsScreen from './screens/results/TravelResultsScreen/TravelResultsScreen';
import TravelPurchaseScreen from './screens/purchases/TravelPurchaseScreen/TravelPurchaseScreen';
import MotoResultsScreen from './screens/results/MotoResultsScreen/MotoResultsScreen';
import MotoPurchaseScreen from './screens/purchases/MotoPurchaseScreen/MotoPurchaseScreen';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePageRedirect />,
  },
  {
    path: '/request/:distanceToken/auto/results',
    element: <AutoResultsScreen />,
  },
  {
    path: '/request/:distanceToken/auto/:quotationId/purchase',
    element: <AutoPurchaseScreen />,
  },
  {
    path: '/request/:distanceToken/autocarro/results',
    element: <AutocarroResultsScreen />,
  },
  {
    path: '/request/:distanceToken/autocarro/:quotationId/purchase',
    element: <AutocarroPurchaseScreen />,
  },
  {
    path: '/request/:distanceToken/motorcycle/results',
    element: <MotoResultsScreen />,
  },
  {
    path: '/request/:distanceToken/moto/:quotationId/purchase',
    element: <MotoPurchaseScreen />,
  },
  {
    path: '/request/:distanceToken/professions/results',
    element: <ProfessionaleResultsScreen />,
  },
  {
    path: '/request/:distanceToken/professions/:quotationId/purchase',
    element: <ProfessionalePurchaseScreen />,
  },
  {
    path: '/request/:distanceToken/infortuni/results',
    element: <InfortuniResultsScreen />,
  },
  {
    path: '/request/:distanceToken/infortuni/:quotationId/purchase',
    element: <InfortuniPurchaseScreen />,
  },
  {
    path: '/request/:distanceToken/infodrive/results',
    element: <InfodriveResultsScreen />,
  },
  {
    path: '/request/:distanceToken/infodrive/:quotationId/purchase',
    element: <InfodrivePurchaseScreen />,
  },
  {
    path: '/request/:distanceToken/travel/results',
    element: <TravelResultsScreen />,
  },
  {
    path: '/request/:distanceToken/travel/:quotationId/purchase',
    element: <TravelPurchaseScreen />,
  },
  {
    path: '*',
    element: <HomePageRedirect />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
