import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container, HeadingInfo, ResultsContainer, TravelResultsScreenStyles,
} from './TravelResultsScreen.styles';
import Header from '../../../components/Reusable/Header';
import Footer from '../../../components/Reusable/Footer';
import TravelCard from '../cards/travel/TravelCard';
import PageLoading from '../../../components/PageLoading/PageLoading';
import { getTravelResults } from '../../../features/results/resultsActions';

function TravelResultsScreen() {
  const { loading, error, data } = useSelector((store) => store.results.travel);
  const { distanceToken } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getTravelResults(distanceToken);
  }, []);

  const aquista = (quotationId) => {
    navigate(`/request/${distanceToken}/travel/${quotationId}/purchase`);
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return (
      <div className="alert alert-danger m-5">
        {error}
      </div>
    );
  }

  return (
    <TravelResultsScreenStyles>
      <Header />
      <Container>
        <HeadingInfo>
          <h2>
            Preventivi assicurazione Viaggi
          </h2>
        </HeadingInfo>
        <ResultsContainer>
          <p>
            <FontAwesomeIcon icon={faCalendarDays} />
            {' '}
            Preventivi aggiorni al
            {new Date().toLocaleDateString('it')}
          </p>
          {data.quotations.map((quote, index) => (
            <TravelCard
              key={index}
              aquista={() => aquista(quote.id)}
              quotation={quote}
              distanceToken={distanceToken}
            />
          ))}
        </ResultsContainer>
      </Container>
      <Footer />
    </TravelResultsScreenStyles>
  );
}

export default TravelResultsScreen;
