import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AddedExtensionButton, AddedExtensionButtonContainer, AddedExtensionNotif, Card,
  CardCol, Container, ExtensionsButton, ExtensionsContainer, ExtensionsList, HeadingInfo,
  IconContainer, InfortuniResultsScreenStyles, PriceCol, ResultsContainer, ResultsContainerColOne,
  ResultsContainerColTwo, ResultsContainerRow,
} from './InfortuniResultsScreen.styles';
import { getInfortuniResult, updateInfortuniExtensions } from '../../../features/results/resultsActions';
import PageLoading from '../../../components/PageLoading/PageLoading';
import metlifeLogo from '../../../assets/companies/MetLife.png';
import Footer from '../../../components/Reusable/Footer';
import Header from '../../../components/Reusable/Header';
import { generalWarningAlert } from '../../../utils/alert';

function InfortuniResultsScreen() {
  const { distanceToken } = useParams();
  const state = useSelector((store) => store.results.infortuni);
  const { loading, error, data } = state;
  const navigate = useNavigate();

  const [extensions, setExtensions] = useState({
    rsm: 0,
    ulc: 0,
  });

  const handleSelected = (seleced) => {
    if (seleced === 'rsm') {
      setExtensions({
        ...extensions,
        rsm: extensions.rsm === 0 ? 1 : 0,
      });
    }
    if (seleced === 'ulc') {
      setExtensions({
        ...extensions,
        ulc: extensions.ulc === 0 ? 1 : 0,
      });
    }
  };

  const updateExtensions = () => {
    if ((extensions.rsm === 0 && extensions.ulc === 1) || (extensions.rsm === 1 && extensions.ulc === 0) || (extensions.rsm === 1 && extensions.ulc === 1)) {
      updateInfortuniExtensions(distanceToken, extensions);
    } else {
      generalWarningAlert('Devi selezionare al meno una estensione!');
    }
  };

  const removeExtension = (extName) => {
    if (extName === 'ulc') {
      const extRemove = {
        ulc: 0,
        rsm: data.quote.rsm !== null ? 1 : 0,
      };

      updateInfortuniExtensions(distanceToken, extRemove);
    }
    if (extName === 'rsm') {
      const extRemove = {
        rsm: 0,
        ulc: data.quote.ulc !== null ? 1 : 0,
      };
      updateInfortuniExtensions(distanceToken, extRemove);
    }
  };

  const aquista = () => {
    navigate(`/request/${distanceToken}/infortuni/${data.quote.quotation_id}/purchase`);
  };

  useEffect(() => {
    getInfortuniResult(distanceToken);
  }, []);

  useEffect(() => {
    if (data) {
      setExtensions({
        rsm: data.quote.rsm === null ? 0 : 1,
        ulc: data.quote.ulc === null ? 0 : 1,
      });
    }
  }, [data]);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return (
      <div className="alert alert-danger m-5">
        {error}
      </div>
    );
  }

  return (
    <InfortuniResultsScreenStyles>
      <Header />
      <Container>
        <HeadingInfo>
          <h2>
            Preventivi assicurazione infortuni
          </h2>
        </HeadingInfo>
        <ResultsContainer>
          <p>
            <FontAwesomeIcon icon={faCalendarDays} />
            {' '}
            Preventivi aggiorni al
            {new Date().toLocaleDateString('it')}
          </p>
          <ResultsContainerRow>
            <ResultsContainerColOne isaquista={data.quote.is_aquista}>
              <Card>
                <CardCol className="company-logo">
                  <img src={metlifeLogo} alt="Metlife Logo" width={150} />
                </CardCol>
                <CardCol className="garanzie">
                  <h4>Garanzie Incluse</h4>
                  <ul>
                    <li>
                      <b>Decesso:</b>
                      {' '}
                      €
                      {parseFloat(data.quote.decesso).toLocaleString('en-US')}
                    </li>
                    <li>
                      <b>
                        Invalidita Permanente:
                      </b>
                      {' '}
                      €
                      {parseFloat(data.quote.invalidita_permanente).toLocaleString('en-US')}
                    </li>
                    <li>
                      <b>Diaria Ricovero: </b>
                      {' '}
                      €
                      {parseFloat(data.quote.diaria_ricovero).toLocaleString('en-US')}
                    </li>
                    <li>
                      <b>Diaria Gesso:</b>
                      {' '}
                      €
                      {parseFloat(data.quote.diaria_gesso).toLocaleString('en-US')}
                    </li>
                    <li>
                      <b>Totale Annuo:</b>
                      {' '}
                      €
                      {parseFloat(data.quote.premio_annuo).toLocaleString('en-US')}
                    </li>
                    {data.quote.ulc !== null
                      && (
                      <>
                        <li>
                          <b>Ustioni / Lussazioni / Comm. Cerebrale:</b>
                          {' '}
                          €
                          {parseFloat(data.quote.ulc).toLocaleString('en-US')}
                        </li>
                        <li>
                          <b>Premio Aggiuntivo:</b>
                          {' '}
                          €
                          {parseFloat(data.quote.ulc_premio_aggiuntivo).toLocaleString('en-US')}
                        </li>
                      </>
                      )}
                    {data.quote.rsm !== null
                      && (
                      <>
                        <li>
                          <b>Rimborsi Spese Medice:</b>
                          {' '}
                          €
                          {parseFloat(data.quote.rsm).toLocaleString('en-US')}
                        </li>
                        <li>
                          <b>Premio Aggiuntivo:</b>
                          {' '}
                          €
                          {parseFloat(data.quote.rsm_premio_aggiuntivo).toLocaleString('en-US')}
                        </li>
                      </>
                      )}
                  </ul>
                </CardCol>
                <PriceCol>
                  <h5>Prezzo Annuo</h5>
                  <h3>
                    €
                    {(parseFloat(data.quote.premio_annuo) + parseFloat(data.quote.ulc_premio_aggiuntivo === null ? 0 : data.quote.ulc_premio_aggiuntivo) + parseFloat(data.quote.rsm_premio_aggiuntivo === null ? 0 : data.quote.rsm_premio_aggiuntivo)).toLocaleString()}
                  </h3>
                  <button onClick={aquista}>Aquista</button>
                  <AddedExtensionButtonContainer>
                    {data.quote.ulc !== null || data.quote.rsm !== null ? <h6>Estensioni Aggiunti</h6> : ''}
                    {data.quote.ulc !== null
                      ? (
                        <AddedExtensionButton>
                          <div>Ustioni / Lussazioni / Commazioni</div>
                          <IconContainer onClick={() => removeExtension('ulc')}>
                            <span>X</span>
                          </IconContainer>
                        </AddedExtensionButton>
                      ) : ''}
                    {data.quote.rsm !== null
                      ? (
                        <AddedExtensionButton>
                          <div>Rimborso Spese Mediche</div>
                          <IconContainer onClick={() => removeExtension('rsm')}>
                            <span>X</span>
                          </IconContainer>
                        </AddedExtensionButton>
                      ) : ''}
                  </AddedExtensionButtonContainer>

                </PriceCol>
              </Card>
            </ResultsContainerColOne>
            <ResultsContainerColTwo>
              <ExtensionsContainer>
                <h3>
                  Seleziona Estensioni da aggiungere
                </h3>
                {parseFloat(data.quote.decesso) !== 50000
                  ? data.quote.rsm === null || data.quote.ulc === null
                    ? (
                      <>
                        <ExtensionsList>
                          {data.quote.ulc === null
                            ? (
                              <li className={`${extensions.ulc === 1 ? 'selected' : ''}`} onClick={() => handleSelected('ulc')}>
                                <div>
                                  {extensions.ulc === 0 ? <FontAwesomeIcon icon={faPlus} color="#00a551" /> : <FontAwesomeIcon icon={faMinus} color="#ffffff" />}
                                </div>
                                {' '}
                                Ustioni / Lussazioni / Commazioni
                              </li>
                            )
                            : ''}
                          {data.quote.rsm === null
                            ? (
                              <li className={`${extensions.rsm === 1 ? 'selected' : ''}`} onClick={() => handleSelected('rsm')}>
                                <div>
                                  {extensions.rsm === 0 ? <FontAwesomeIcon icon={faPlus} color="#00a551" /> : <FontAwesomeIcon icon={faMinus} color="#ffffff" />}
                                </div>
                                {' '}
                                Rimborso Spese Mediche
                              </li>
                            )
                            : ''}
                        </ExtensionsList>
                        <ExtensionsButton onClick={updateExtensions}>Aggiungi</ExtensionsButton>
                      </>
                    )
                    : <AddedExtensionNotif>Estensioni aggiunte!</AddedExtensionNotif>
                  : <AddedExtensionNotif>Estensioni non disponibili per questo piano!</AddedExtensionNotif>}
              </ExtensionsContainer>
            </ResultsContainerColTwo>
          </ResultsContainerRow>
        </ResultsContainer>
      </Container>
      <Footer />
    </InfortuniResultsScreenStyles>
  );
}

export default InfortuniResultsScreen;
