import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faXmark, faDownload, faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { DocumentRowContainer, DocumentsUploadedContainer } from './DocumentsUploaded.styles';

const generateStatus = (doc) => {
  let statusLabelType;
  let statusName;

  if (doc.is_uploaded_valid === null && doc.signable_document === 0) {
    statusLabelType = 'warning';
    statusName = 'Documento in verifica';
  } else if (doc.is_uploaded_valid === 1 && doc.signable_document === 0) {
    statusLabelType = 'success';
    statusName = 'Valido';
  } else if (doc.signable_document === 1 && doc.signed_document_filename === null && doc.is_signed_valid === null) {
    statusLabelType = 'warning';
    statusName = 'In Attesa Firma';
  } else if (doc.signable_document === 1 && doc.signed_document_filename !== null && doc.is_signed_valid === null) {
    statusLabelType = 'warning';
    statusName = 'Firma in verifica';
  } else if (doc.signable_document === 1 && doc.is_signed_valid === 1) {
    statusLabelType = 'success';
    statusName = 'Firmato';
  } else if (doc.signable_document === 1 && doc.is_signed_valid === 0) {
    statusLabelType = 'danger';
    statusName = 'Firma non valida';
  }

  return (
    <div className={`status-label status-label-${statusLabelType}`}>
      {statusName}
    </div>
  );
};

export function DocumentsUploaded({
  signable, documents,
  downloadDocument, removeDocument,
  downloadOptionalDocument, removeOptionalDocument,
}) {
  return (
    <DocumentsUploadedContainer>
      <div className="title">Documenti Caricati</div>

      {documents.length === 0
            && <div className="no-results">Nessun documento caricato.</div>}

      {documents.map((doc) => {
        const statusLabel = generateStatus(doc);

        if ((signable === 1 && doc.is_signed_valid === 1) || (signable === 0 && doc.is_uploaded_valid === 1)) {
          return (
            <DocumentRowContainer key={doc.id}>
              <div className="main">
                <div className="content">
                  <div
                    className="status-badge badge rounded-circle text-bg-success d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <div className="filename">{doc.original_filename}</div>
                </div>
                {statusLabel}
                <div className="actions">
                  <div className="button" onClick={() => ((doc.optional_document_request_id === undefined) ? downloadDocument(doc.id) : downloadOptionalDocument(doc.id))}>
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                </div>
              </div>
            </DocumentRowContainer>
          );
        }

        if ((signable === 1 && doc.is_signed_valid === 0) || (signable === 0 && doc.is_uploaded_valid === 0)) {
          return (
            <DocumentRowContainer key={doc.id}>
              <div className="main">
                <div className="content">
                  <div
                    className="status-badge badge rounded-circle text-bg-danger d-flex align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                  <div className="filename">{doc.original_filename}</div>
                </div>
                {statusLabel}
                <div className="actions">
                  <div className="button">
                    <FontAwesomeIcon icon={faDownload} onClick={() => ((doc.optional_document_request_id === undefined) ? downloadDocument(doc.id) : downloadOptionalDocument(doc.id))} />
                  </div>
                  {signable === 0 && doc.is_uploaded_valid === 0
                                    && (
                                    <div className="button delete-button" onClick={() => ((doc.optional_document_request_id === undefined) ? removeDocument(doc.id) : removeOptionalDocument(doc.id))}>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    )}
                </div>
              </div>
              {doc.motivation !== null
                            && <div className="motivation">{doc.motivation}</div>}
            </DocumentRowContainer>
          );
        }

        return (
          <DocumentRowContainer key={doc.id}>
            <div className="main">
              <div className="content">
                <div className="filename">{doc.original_filename}</div>
              </div>
              {statusLabel}
              <div className="actions">
                <div className="button" onClick={() => ((doc.optional_document_request_id === undefined) ? downloadDocument(doc.id) : downloadOptionalDocument(doc.id))}>
                  <FontAwesomeIcon icon={faDownload} />
                </div>
              </div>
            </div>
          </DocumentRowContainer>
        );
      })}
    </DocumentsUploadedContainer>
  );
}
