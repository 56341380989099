import { quotationsAxe } from '../../services/axios-client/quotationsAxeClient';

import caller from './resultsCaller';

const notFoundErrorMessage = 'Non siamo riusciti a trovare la richiesta o il collegamento è scaduto';

/**
 * Infortuni
 */

export const getInfortuniResult = async (distanceToken) => {
  caller.infortuniResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/infortuni/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.infortuniResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.infortuniResultsRejected('An error occured');
    return;
  }

  caller.infortuniResultsFulfilled(response.data.data);
};

export const updateInfortuniExtensions = async (distanceToken, data) => {
  caller.infortuniExtensionsPending();

  const response = await quotationsAxe.put(
    `/guarantees/infortuni/${distanceToken}/update`,
    data,
  );

  if (response.status !== 200) {
    caller.infortuniExtensionsRejected('An Error Occured');
    return;
  }

  getInfortuniResult(distanceToken);
};

/**
 * Professionale
 */
export const getProfessionaleResult = async (distanceToken) => {
  caller.professionaleResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/professions/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.professionaleResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.professionaleResultsRejected('An error occured');
    return;
  }

  caller.professionaleResultsFulfilled(response.data.data);
};

export const addProfessionaleExtension = async (
  distanceToken,
  extensions,
  collectData,
) => {
  caller.professionaleResultsPending();

  await quotationsAxe.put(`/guarantees/profession/${distanceToken}/update`, {
    extensions,
  });

  collectData();
};

/**
 * Auto
 */
export const getAutoResult = async (distanceToken) => {
  caller.autoResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/vehicles/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.autoResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.autoResultsRejected('An error occured');
    return;
  }

  caller.autoResultsFulfilled(response.data.data);
};

export const updateAutoExtensions = async (distanceToken, extensions) => {
  caller.autoResultsPending();

  await quotationsAxe.put(`/guarantees/vehicles/${distanceToken}/update`, {
    coverages: extensions,
  });

  getAutoResult(distanceToken);
};

/**
 * Autocarro
 */
export const getAutocarroResult = async (distanceToken) => {
  caller.autocarroResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/vehicles/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.autocarroResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.autocarroResultsRejected('An error occured');
  }

  caller.autocarroResultsFulfilled(response.data.data);
};

export const updateAutocarroExtensions = async (distanceToken, extensions) => {
  caller.autocarroResultsPending();

  await quotationsAxe.put(`/guarantees/vehicles/${distanceToken}/update`, {
    coverages: extensions,
  });

  getAutocarroResult(distanceToken);
};

/**
 * Infodrive
 */
export const getInfodriveResult = async (distanceToken) => {
  caller.infodriveResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/infodrive/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.infodriveResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.infodriveResultsRejected('An error occured');
    return;
  }

  caller.infodriveResultsFulfilled(response.data.data);
};

/**
 * Travel
 */

export const getTravelResults = async (distanceToken) => {
  caller.travelResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/travel/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.travelResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.travelResultsRejected('An error occured');
    return;
  }

  caller.travelResultsFulfilled(response.data);
};

export const updateTravelGuarantee = async (
  quoteId,
  coverageId,
  distanceToken,
) => {
  const res = await quotationsAxe.put(`/guarantees/travel/${quoteId}/update`, {
    coverage_id: coverageId,
  });

  if (res.status === 200) {
    const response = await quotationsAxe.get(
      `/quotation-results/travel/${distanceToken}`,
    );
    caller.travelResultsFulfilled(response.data);
  }
};

/**
 * Moto
 */
export const getMotoResult = async (distanceToken) => {
  caller.motoResultsPending();

  const response = await quotationsAxe.get(
    `/quotation-results/motorcycle/${distanceToken}`,
  );

  if (response.status === 404) {
    caller.motoResultsRejected(notFoundErrorMessage);
    return;
  }

  if (response.status !== 200) {
    caller.motoResultsRejected('An error occured');
    return;
  }

  caller.motoResultsFulfilled(response.data.data);
};

export const updateMotoExtensions = async (distanceToken, extensions) => {
  caller.motoResultsPending();

  await quotationsAxe.put(`/guarantees/motorcycle/${distanceToken}/update`, {
    coverages: extensions,
  });

  getMotoResult(distanceToken);
};
