import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, PurchaseScreenStyles, Title } from '../styles/common.styles';
import Header from '../../../components/Reusable/Header';
import Footer from '../../../components/Reusable/Footer';
import Stepper from '../../../components/Reusable/Stepper';
import { PaymentContainer } from '../../../components/Reusable/Purchase/PaymentContainer/PaymentContainer';
import PageLoading from '../../../components/PageLoading/PageLoading';
import { getTravelPurchase } from '../../../features/purchase/purchaseActions';

function TravelPurchaseScreen() {
  const { distanceToken, quotationId } = useParams();
  const state = useSelector((store) => store.purchase.travel);
  const {
    loading, error, quotation,
  } = state;

  const collectQuoteData = () => {
    getTravelPurchase(distanceToken, quotationId);
  };

  useEffect(() => {
    collectQuoteData();
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <div className="alert alert-danger m-5">{error}</div>;
  }

  const quoteStatus = quotation.status;

  return (
    <PurchaseScreenStyles>
      <Header />
      <Container>
        <Title>Processo di aquisto Viaggi</Title>
        <Stepper quoteStatus={quoteStatus} noDocumentsManagement />
        {quoteStatus === 4
          && (
          <PaymentContainer
            distanceToken={distanceToken}
            quotationId={quotationId}
            quotation={quotation}
            collectQuoteData={collectQuoteData}
          />
          )}

        {quoteStatus === 6
          && (
          <div
            className="alert alert-success mt-3"
            role="alert"
          >
            <h4 className="alert-heading">
              In Attesa Emissione
            </h4>
            <p>
              Quando l'agente di backoffice carica la
              polizza, riceverai una notifica e il
              contratto potrà essere scaricato.
            </p>
          </div>
          )}

        {quoteStatus === 7
          && (
          <div
            className="alert alert-success mt-3"
            role="alert"
          >
            <h4 className="alert-heading">
              Processo Completato
            </h4>
            <p>
              Hai concluso il processo di acquisto.
            </p>
          </div>
          )}

      </Container>
      <Footer />
    </PurchaseScreenStyles>
  );
}

export default TravelPurchaseScreen;
