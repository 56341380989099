import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { addProfessionaleExtension, getProfessionaleResult } from '../../../features/results/resultsActions';
import PageLoading from '../../../components/PageLoading/PageLoading';
import {
  Container, Card, ExtensionsContainer, HeadingInfo, ProfessionaleResultsScreenStyles, ResultsContainer, ResultsContainerColOne, ResultsContainerColTwo, ResultsContainerRow, ExtensionsList,
} from './ProfessionaleResultsScreen.styles';
import Header from '../../../components/Reusable/Header';
import { BACKEND_BASE_URL } from '../../../configs/urls';
import Footer from '../../../components/Reusable/Footer';

function ProfessionaleResultsScreen() {
  const navigate = useNavigate();
  const { distanceToken } = useParams();
  const state = useSelector((store) => store.results.professionale);
  const { loading, error, data } = state;

  const aquista = (quotationId) => {
    navigate(`/request/${distanceToken}/professions/${quotationId}/purchase`);
  };

  const collectData = () => {
    getProfessionaleResult(distanceToken);
  };

  useEffect(() => {
    collectData();
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return (
      <div className="alert alert-danger m-5">
        {error}
      </div>
    );
  }

  const availableExtensions = data.profession_extensions.filter((e) => [
    'Tutela legale Bronze',
    'Tutela legale Silver',
    'Tutela legale Gold',
  ].includes(e.name));

  const selectedExtension = availableExtensions.find((e) => JSON.parse(data.insurance_request_profession.extensions).includes(e.code));

  const handleSelected = async (code) => {
    let extensions = JSON.parse(data.insurance_request_profession.extensions);

    if (code === selectedExtension?.code) {
      extensions = extensions.filter((v) => v !== code);
    } else {
      extensions = extensions.filter((v) => !['tlb', 'tls', 'tlg'].includes(v));
      extensions.push(code);
    }

    addProfessionaleExtension(distanceToken, extensions.join(','), collectData);
  };

  return (
    <ProfessionaleResultsScreenStyles>
      <Header />
      <Container>
        <HeadingInfo>
          <h2>Preventivi assicurazione professionale</h2>
        </HeadingInfo>
        <ResultsContainer>
          <p>
            <FontAwesomeIcon icon={faCalendarDays} />
            {' '}
            Preventivi aggiorni al
            {new Date().toLocaleDateString('it')}
          </p>
          <ResultsContainerRow>
            <ResultsContainerColOne>
              <Card isaquista={data.quotation.is_aquista}>
                <div className="company-logo">
                  <img src={BACKEND_BASE_URL + data.company.logo} alt="Professionale Logo" width={150} />
                </div>
                <div className="garanzie">
                  <h4>Garanzie Incluse</h4>

                  <div className="card-col-row">
                    <b>Prodotto:</b>
                    <span>RC Professionale</span>
                  </div>

                  <div className="card-col-row">
                    <b>Compagnia:</b>
                    <span>Tokio Marine HCC</span>
                  </div>

                  <div className="card-col-row">
                    <b>Professione:</b>
                    <span>{data.insurance_request_profession.profession_desc}</span>
                  </div>

                  <div className="card-col-row">
                    <b>Massimale:</b>
                    <span>
                      {Number(data.insurance_request_profession.billed_maximum).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                      &euro;
                    </span>
                  </div>

                  <div className="card-col-row">
                    <b>Franchigia:</b>
                    <span>1,000&euro;</span>
                  </div>

                  <div className="card-col-row">
                    <b>Retroattività:</b>
                    <span>{data.insurance_request_profession.retroactivity_name}</span>
                  </div>

                  <div className="card-col-row">
                    <b>Scaglione fatturato:</b>
                    <span>
                      {data.insurance_request_profession.billed}
                      &euro;
                    </span>
                  </div>

                  <div className="card-col-row">
                    <b>Tempo medio di emissione:</b>
                    <span>2 giorni</span>
                  </div>

                </div>
                <div className="price">
                  <h5>Prezzo Annuo</h5>
                  <h3>
                    €
                    {parseFloat(data.quotation.amount).toLocaleString()}
                  </h3>
                  <button className="button-aquista" onClick={() => aquista(data.quotation.id)}>Aquista</button>
                </div>
              </Card>
              {selectedExtension !== undefined
                            && (
                            <Card className={`package-card package-card-${selectedExtension.code}`}>
                              <div className="company-logo">
                                <img src={BACKEND_BASE_URL + data.company.logo} alt="Professionale Logo" width={150} />
                              </div>
                              <div className="garanzie">
                                <h4>Informazioni</h4>

                                <div className="card-col-row">
                                  <b>Prodotto:</b>
                                  <span>Tutela Legale</span>
                                </div>

                                <div className="card-col-row">
                                  <b>Compagnia:</b>
                                  <span>Tokio Marine HCC</span>
                                </div>

                                {selectedExtension?.code === 'tlb'
                                        && (
                                        <div className="card-col-row">
                                          <b>Copertura BRONZE</b>
                                          <span>Copertura ambito civile, penale e libera scelta del legale</span>
                                        </div>
                                        )}

                                {selectedExtension?.code === 'tls'
                                        && (
                                        <div className="card-col-row">
                                          <b>Copertura SILVER</b>
                                          <span>Copertura ambito civile, penale e amministrativo con estensione ai DL81/2008, DL 196/03, DL 193/07, DL 152/06 e Regolamento (UE) n. 2016/679, senza libera scelta del legale</span>
                                        </div>
                                        )}

                                {selectedExtension?.code === 'tlg'
                                        && (
                                        <div className="card-col-row">
                                          <b>Copertura GOLD</b>
                                          <span>Copertura ambito civile, penale e amministrativo con estensione ai DL81/2008, DL 196/03, DL 193/07, DL 152/06 e Regolamento (UE) n. 2016/679, libera scelta del legale</span>
                                        </div>
                                        )}

                                <div className="card-col-row">
                                  <b>Massimale</b>
                                  <span>10.000,00€</span>
                                </div>

                                <div className="card-col-row">
                                  <b>Premio</b>
                                  <span>
                                    {Number(selectedExtension.price).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                                    &euro;
                                  </span>
                                </div>

                              </div>
                              <div className="price">
                                <button className="button-aquista" onClick={() => handleSelected(selectedExtension.code)}>Rimuovi</button>
                              </div>
                            </Card>
                            )}
            </ResultsContainerColOne>
            <ResultsContainerColTwo>
              <ExtensionsContainer>
                <h3>
                  Seleziona Estensioni da aggiungere
                </h3>

                <ExtensionsList>
                  {availableExtensions.map((e) => (
                    <li
                      key={e.code}
                      className={`${selectedExtension?.code === e.code ? 'selected' : ''}`}
                      onClick={() => handleSelected(e.code)}
                    >
                      <div>
                        {selectedExtension?.code === e.code
                          ? <FontAwesomeIcon icon={faMinus} color="#ffffff" />
                          : <FontAwesomeIcon icon={faPlus} color="#00a551" />}
                      </div>
                      {' '}
                      {e.name}
                    </li>
                  ))}
                </ExtensionsList>
              </ExtensionsContainer>
            </ResultsContainerColTwo>
          </ResultsContainerRow>
        </ResultsContainer>
      </Container>
      <Footer />
    </ProfessionaleResultsScreenStyles>
  );
}

export default ProfessionaleResultsScreen;
