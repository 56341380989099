import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container, ExtensionsContainer, ExtensionsList, HeadingInfo,
  AutoResultsScreenStyles, ResultsContainer, ResultsContainerColOne,
  ResultsContainerColTwo, ResultsContainerRow,
} from './AutoResultsScreen.styles';
import { getAutoResult, updateAutoExtensions } from '../../../features/results/resultsActions';
import PageLoading from '../../../components/PageLoading/PageLoading';
import Footer from '../../../components/Reusable/Footer';
import Header from '../../../components/Reusable/Header';
import AdriaticCard from '../cards/vehicles/AdriaticCard';
import PrimaCard from '../cards/vehicles/PrimaCard';
import InfodriveCard from '../cards/vehicles/InfodriveCard';
import PreventivassCard from '../cards/vehicles/PreventivassCard';
import PreventivassCardLinearAndDallbogg from '../cards/vehicles/PreventivassCardLinearAndDallbogg';

function AutoResultsScreen() {
  const { distanceToken } = useParams();
  const state = useSelector((store) => store.results.auto);
  const { loading, error, data } = state;
  const navigate = useNavigate();
  const [currentQuotes, setCurrentQuotes] = useState('diretta');
  // const [orderedQuotes, setOrderedQuotes] = useState([]);
  // ? diretta = Greenia Companies
  // ? indiretta = Preventivass Companies

  const coveragesList = [
    { key: 'rca', name: 'RCA (sempre inclusa)', disabled: true },
    { key: 'furto', name: 'Furto' },
    { key: 'incendio', name: 'Incendio' },
    { key: 'assistenza_stradale', name: 'Assistenza stradale' },
    { key: 'infortuni_conducente', name: 'Infortuni conducente' },
    { key: 'tutela_legale', name: 'Tutela legale' },
    { key: 'collisione', name: 'Kasco collisione' },
    { key: 'cristalli', name: 'Cristalli' },
    { key: 'rivalsa', name: 'Zero rivalsa per ebbrezza' },
    { key: 'kasko', name: 'Kasko completa' },
    { key: 'eventi_naturali', name: 'Eventi Naturali' },
    { key: 'eventi_sociopolitici', name: 'Atti vandalici' },
  ];

  const handleCurrentQuotesDisplay = (val) => {
    setCurrentQuotes(val);
  };

  const aquista = (quotationId) => {
    navigate(`/request/${distanceToken}/auto/${quotationId}/purchase`);
  };

  const aquistaInfodrive = (quotationId) => {
    navigate(`/request/${distanceToken}/infodrive/${quotationId}/purchase`);
  };

  useEffect(() => {
    getAutoResult(distanceToken);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return (
      <div className="alert alert-danger m-5">
        {error}
      </div>
    );
  }

  const coverages = data.coverages !== '' ? data.coverages.split(',') : [];

  const handleSelected = (extensionKey) => {
    if (coverages.includes(extensionKey)) {
      updateAutoExtensions(distanceToken, coverages.filter((v) => v !== extensionKey));
    } else {
      updateAutoExtensions(distanceToken, [...coverages, extensionKey]);
    }
  };

  const renderExtension = (extensionKey, extensionName, disabled) => (
    <li className={`${(disabled || coverages.includes(extensionKey)) ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} onClick={() => (!disabled ? handleSelected(extensionKey) : () => { })}>
      <div>
        {(!disabled && !coverages.includes(extensionKey)) ? <FontAwesomeIcon icon={faPlus} color="#00a551" /> : <FontAwesomeIcon icon={faMinus} color="#ffffff" />}
      </div>
      {' '}
      {extensionName}
    </li>
  );

  return (
    <AutoResultsScreenStyles>
      <Header />
      <Container>
        <HeadingInfo>
          <h2>
            Preventivi assicurazione Auto
          </h2>
        </HeadingInfo>
        <ResultsContainer>
          <p>
            <FontAwesomeIcon icon={faCalendarDays} />
            {' '}
            Preventivi aggiorni al
            {new Date().toLocaleDateString('it')}
          </p>
          <div className="row my-2">
            <div className="d-flex align-items-stretch justify-content-center gap-2 mb-3">
              <button className={`btn ${currentQuotes === 'diretta' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('diretta')}>Compagnie Dirette</button>
              <button className={`btn ${currentQuotes === 'indiretta' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('indiretta')}>Compagnie Indirette</button>
            </div>
          </div>
          <ResultsContainerRow>
            <ResultsContainerColOne>
              {data.quotations.map((quote) => {
                if (quote.company_id !== null) {
                  if (currentQuotes === 'diretta') {
                    if (quote.company.id === 1) {
                      return <AdriaticCard insuranceRequest={data} quotation={quote} aquista={() => aquista(quote.id)} coverages={coverages} />;
                    }

                    if (quote.company.id === 2) {
                      return <PrimaCard insuranceRequest={data} quotation={quote} aquista={() => aquista(quote.id)} coverages={coverages} />;
                    }

                    if (quote.added_manually === 1) {
                      return <PreventivassCard insuranceRequest={data} quotation={quote} aquista={() => aquista(quote.id)} />;
                    }
                  }
                  if (quote.company_id === 9 || quote.company_id === 10) {
                    return <PreventivassCardLinearAndDallbogg insuranceRequest={data} quotation={quote} aquista={() => aquista(quote.id)} />;
                  }
                }
                if (currentQuotes === 'indiretta' && quote.company_id === null) {
                  return <PreventivassCard insuranceRequest={data} quotation={quote} aquista={() => aquista(quote.id)} />;
                }

                return null;
              })}

              {data.related_quotations.length !== 0 && data.related_quotations[0].quotations.length !== 0
                && (
                  <>
                    <h1>Prodotti Correlati</h1>
                    <InfodriveCard
                      aquista={aquistaInfodrive}
                      quotation={data.related_quotations[0].quotations[0]}
                      insuranceRequest={data.related_quotations[0]}
                    />
                  </>
                )}
            </ResultsContainerColOne>
            <ResultsContainerColTwo>
              <ExtensionsContainer>
                <h3>Aggiungi granzie</h3>
                <ExtensionsList>
                  {coveragesList.map((c) => renderExtension(c.key, c.name, c.disabled))}
                </ExtensionsList>
              </ExtensionsContainer>
            </ResultsContainerColTwo>
          </ResultsContainerRow>
        </ResultsContainer>
      </Container>
      <Footer />
    </AutoResultsScreenStyles>
  );
}

export default AutoResultsScreen;
