import React from 'react';
import { LogoHeader } from './Header.styles';
import logoGreenia from '../../assets/images/logo-white.svg';

function Header() {
  return (
    <LogoHeader>
      <a href="https://greenia.it" target="_blank" rel="noreferrer">
        <img src={logoGreenia} alt="Grenia Logo" />
      </a>
    </LogoHeader>
  );
}

export default Header;
