import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleDot, faO } from '@fortawesome/free-solid-svg-icons';
import {
  ProgressLine, StatusIcon, Step, StepBox, StepperContainer,
} from './Stepper.styles';

function Stepper({
  quoteStatus,
  noDocumentsManagement,
}) {
  const stepsOrder = [1, 5, 2, 4, 6, 7];

  const getProgressClassName = (status) => {
    if (stepsOrder.indexOf(status) < stepsOrder.indexOf(quoteStatus)) {
      return 'completed';
    } if (stepsOrder.indexOf(status) === stepsOrder.indexOf(quoteStatus)) {
      return 'active';
    }

    return '';
  };

  const getProgressIcon = (status) => {
    if (stepsOrder.indexOf(status) < stepsOrder.indexOf(quoteStatus)) {
      return faCircleCheck;
    } if (stepsOrder.indexOf(status) === stepsOrder.indexOf(quoteStatus)) {
      return faCircleDot;
    }

    return faO;
  };

  if (noDocumentsManagement === true) {
    return (
      <StepperContainer>
        <Step>
          <StepBox>
            <ProgressLine className={getProgressClassName(4)} />
            <StatusIcon className={getProgressClassName(4)}>
              <div className="icon">
                <FontAwesomeIcon icon={getProgressIcon(4)} />
              </div>
              <p>Attesa Pagamento</p>
            </StatusIcon>
          </StepBox>
        </Step>
        <Step>
          <StepBox>
            <ProgressLine className={getProgressClassName(6)} />
            <StatusIcon className={getProgressClassName(6)}>
              <div className="icon">
                <FontAwesomeIcon icon={getProgressIcon(6)} />
              </div>
              <p>In Attesa Emissione</p>
            </StatusIcon>
          </StepBox>
        </Step>
      </StepperContainer>
    );
  }

  return (
    <StepperContainer>
      <Step>
        <StepBox>
          <ProgressLine className={getProgressClassName(1)} />
          <StatusIcon className={getProgressClassName(1)}>
            <div className="icon">
              <FontAwesomeIcon icon={getProgressIcon(1)} />
            </div>
            <p>Attesa Documenti</p>
          </StatusIcon>
        </StepBox>
      </Step>
      <Step>
        <StepBox>
          <ProgressLine className={getProgressClassName(5)} />
          <StatusIcon className={getProgressClassName(5)}>
            <div className="icon">
              <FontAwesomeIcon icon={getProgressIcon(5)} />
            </div>
            <p>Attesa Smarcatura</p>
          </StatusIcon>
        </StepBox>
      </Step>
      <Step>
        <StepBox>
          <ProgressLine className={getProgressClassName(2)} />
          <StatusIcon className={getProgressClassName(2)}>
            <div className="icon">
              <FontAwesomeIcon icon={getProgressIcon(2)} />
            </div>
            <p>Problemi Con i Documenti</p>
          </StatusIcon>
        </StepBox>
      </Step>
      <Step>
        <StepBox>
          <ProgressLine className={getProgressClassName(4)} />
          <StatusIcon className={getProgressClassName(4)}>
            <div className="icon">
              <FontAwesomeIcon icon={getProgressIcon(4)} />
            </div>
            <p>Attesa Pagamento</p>
          </StatusIcon>
        </StepBox>
      </Step>
      <Step>
        <StepBox>
          <ProgressLine className={getProgressClassName(6)} />
          <StatusIcon className={getProgressClassName(6)}>
            <div className="icon">
              <FontAwesomeIcon icon={getProgressIcon(6)} />
            </div>
            <p>In Attesa Emissione</p>
          </StatusIcon>
        </StepBox>
      </Step>
    </StepperContainer>
  );
}

export default Stepper;
