import styled from 'styled-components';

export const InputTextStyled = styled.div`
    padding: 10px 0;

    .label {
        margin: auto;
        margin-bottom: 3px;
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: rgb(120,120,120);
    }

    .form-control {
        border: 2px solid rgb(160,160,160);
    }

    .form-control.is-valid {
        border-color: var(--bs-form-valid-color);
    }

    .form-control.is-invalid {
        border-color: var(--bs-form-invalid-color);
    }
`;
