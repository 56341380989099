import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { OptionalDocumentModalStyles } from './OptionalDocumentModal.styles';
import { generalAlertError, generalAlertSuccessToast } from '../../../../utils/alert';
import { backAxe } from '../../../../services/axios-client/backAxeClient';
import { InputSelect } from '../../../formInputs/InputSelect';

const schema = yup.object({
  optional_document_type_id: yup
    .string()
    .required('Il campo è obbligatorio.'),
});

function OptionalDocumentModal({
  distanceToken, quotationId, closeModal, collectQuoteData,
}) {
  const [optionalDocumentTypes, setOptionalDocumentTypes] = useState([]);

  useEffect(() => {
    const run = async () => {
      const response = await backAxe.get(`/request/${distanceToken}/quotations/${quotationId}/get-optional-document-types`);

      if (response.status === 200) {
        setOptionalDocumentTypes(response.data.data);
      }
    };

    run();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      optional_document_type_id: null,
    },
  });

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const sendOptionalDocumentRequest = async (data) => {
    const response = await backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/optional-documents/add-type`, data);

    if (response.status === 400) {
      if (response.data.error === 'cannot_add_more_document_types') {
        generalAlertError('Non è possibile aggiungere più di 5 tipi di documento');
        closeModal();
        return;
      }
    }

    if (response.status !== 200) {
      generalAlertError('Non è possibile aggiungere il tipo di documento');
      closeModal();
      return;
    }

    generalAlertSuccessToast('Tipo di documento aggiunto');
    closeModal();
    collectQuoteData();
  };

  return (
    <OptionalDocumentModalStyles>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="optional-document-boxLabel">
              Documento facoltativo
            </h1>
            <button
              id="close-modal-button"
              type="button"
              className="btn-close"
              onClick={() => closeModal()}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(sendOptionalDocumentRequest)}>
              <InputSelect
                placeholder="Seleziona tipo di documento"
                label="Tipo di documento"
                registration={register('optional_document_type_id')}
                error={errors.optional_document_type_id?.message}
                valid={isValid('optional_document_type_id')}
                options={optionalDocumentTypes}
              />

              <button
                type="submit"
                className="btn btn-outline-success px-4 w-100"
              >
                Richiedi l'aggiunta di un documento facoltativo
              </button>
            </form>
          </div>
        </div>
      </div>
    </OptionalDocumentModalStyles>
  );
}

export default OptionalDocumentModal;
