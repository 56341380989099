import { backAxe } from './backAxeClient';
import { downloadFile } from './axeHelpers';

export const greeniaPrivacyPolicy = async () => {
  const response = await backAxe.get('/greenia-privacy-policy', {
    responseType: 'blob',
  });

  if (response.status !== 200) {
    return;
  }

  downloadFile(response.data);
};

export const greeniaPrecontrattuale = async () => {
  const response = await backAxe.get('/greenia-precontrattuale', {
    responseType: 'blob',
  });

  if (response.status !== 200) {
    return;
  }

  downloadFile(response.data);
};

// export const downloadResourceDocs = async (id, professionId) => {
//   const response = await backAxe.get(
//     `/download-resource-document/${id}/${professionId}`,
//     {
//       responseType: 'blob',
//     },
//   );

//   if (response.status !== 200) {
//     return;
//   }

//   downloadFile(response.data);
// };

export const deleteDocument = (distanceToken, quotationId, documentId) => backAxe.delete(`/request/${distanceToken}/quotations/${quotationId}/documents/${documentId}`, {});

export const apiDownloadDocument = (distanceToken, quotationId, documentId) => backAxe.get(`/request/${distanceToken}/quotations/${quotationId}/documents/${documentId}/download`);

export const uploadDocument = (distanceToken, quotationId, data) => backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/documents/upload`, data);

export const documentMethodManualUpload = (distanceToken, quotationId, documentId, data) => backAxe.post(
  `/request/${distanceToken}/quotations/${quotationId}/manually/sign/documents/${documentId}/upload-signed`,
  data,
);

export const selectSignMethod = async (distanceToken, quotationId, signMethod, signEmail) => {
  if (signMethod === 0) {
    await backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/manually/sign/documents`);
    return;
  }

  await backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/digitally/sign/documents`, {
    signature_mail: signEmail,
  });
};

export const apiUploadOptionalDocument = (distanceToken, quotationId, data) => backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/optional-documents/upload`, data);

export const apiDeleteOptionalDocument = (distanceToken, quotationId, documentId) => backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/optional-documents/delete/${documentId}`, {});

export const apiDownloadOptionalDocument = (distanceToken, quotationId, documentId) => backAxe.get(`/request/${distanceToken}/quotations/${quotationId}/optional-documents/${documentId}/download`);

export const apiOptionalDocumentMethodManualUpload = (distanceToken, quotationId, documentId, data) => backAxe.post(
  `/request/${distanceToken}/quotations/${quotationId}/manually/sign/optional-documents/${documentId}/upload-signed`,
  data,
);
