import styled from 'styled-components';

export const StepperContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const Step = styled.div`
  width: 100%;
`;

export const StepBox = styled.div``;

export const ProgressLine = styled.div`
  width: 98%;
  height: 6px;
  background-color: lightgray;

  &.active {
    height: 9px;
    background-color: gray;
  }

  &.completed {
    background-color: #00a551;
  }
`;

export const StatusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  svg {
    font-size: 22px;
    color: lightgray;
  }

  p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    color: lightgray;
  }

  &.active {
    svg {
      font-size: 22px;
      color: gray;
    }

    p {
      color: gray;
    }
  }

  &.completed {
    svg {
      font-size: 22px;
      color: #00a551;
    }

    p {
      color: #00a551;
    }
  }

  @media (max-width: 900px) {
    p {
      font-size: 10px;
    }

    .icon {
      display: none;
    }
  }
`;
