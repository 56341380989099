import React from 'react';
import { Card } from './styles/common.styles';
import { BACKEND_BASE_URL } from '../../../../configs/urls';
import { guideTypes } from './utils/guide_types';

const getPaymentFrequency = (paymentFrequency) => {
  const pf = {
    1: 'Annuo',
    2: 'Semestrale',
  };

  return pf[paymentFrequency];
};

function PreventivassCardLinearAndDallbogg({ insuranceRequest, quotation, aquista }) {
  return (
    <Card isAquista={quotation.is_aquista}>
      <img className="company-logo" src={`${BACKEND_BASE_URL}${quotation.company.logo}`} />
      <div className="information">
        <div className="information-row">
          <div className="information-label">Compagnia:</div>
          <div className="information-value">{quotation.company.name}</div>
        </div>
        <div className="information-row">
          <div className="information-label">Guida:</div>
          <div className="information-value">{guideTypes.find((v) => v.id === insuranceRequest.guide_type).name}</div>
        </div>
        <div className="information-row">
          <div className="information-label">Garanzie:</div>
          <div className="information-value">Nessuna garanzia disponibile</div>
        </div>
        <div className="information-row">
          <div className="information-label">Frequenza di pagamento:</div>
          <div className="information-value">
            <div className="badge badge-info">{getPaymentFrequency(quotation.payment_frequency)}</div>
          </div>
        </div>
      </div>
      <div className="pricing">
        <div className="pricing-label">Prezzo Annuo</div>
        <div className="pricing-amount">
          €
          {' '}
          {Number(
            quotation.amount,
          ).toLocaleString('it-IT', {
            minimumFractionDigits: 2,
          })}
        </div>
        <div className="aquista-button" onClick={() => aquista()}>
          Acquista
        </div>
      </div>
    </Card>
  );
}

export default PreventivassCardLinearAndDallbogg;
