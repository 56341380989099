import styled from 'styled-components';

export const DocumentsSignCardContainer = styled.div`
    width: 100%;
    border: 1px solid grey;
    border-radius: 10px;
`;

export const DocumentsSignCardContainerRow = styled.div`
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
        font-weight: bold;
    }

    .value {
        display: flex;
    }

    @media (max-width: 700px) {
        flex-direction: column;
    }

    .button-outline {
        margin: 5px;
        padding: 8px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: .3s;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .button-outline-success {
        border: 1px solid var(--greenia-color);
        color: var(--greenia-color);
    }

    .button-outline-success:hover {
        background-color: rgb(0,166,81,.1);
    }

    .button-outline-dark {
        border: 1px solid black;
        color: black;
    }

    .button-outline-dark:hover {
        background-color: rgb(0,0,0,.1);
    }

    .badge-success {
        background-color: var(--greenia-color);
    }
`;

export const DocumentsSignCardContainerDivider = styled.div`
    width: 100%;
    border-bottom: 1px solid grey;
`;
