import styled from 'styled-components';

export const AlertBoxStyles = styled.div`
  background-color: ${(props) => {
    if (props.type === 'success') {
      return '#00a551';
    }

    if (props.type === 'error') {
      return 'red';
    }

    if (props.type === 'warning') {
      return 'orange';
    }

    return '#00a551';
  }};
  padding: 15px 30px;
  width: 100%;
  border-radius: 10px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  margin: 5px 0;
`;
