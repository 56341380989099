import { configureStore } from '@reduxjs/toolkit';

import { resultsSlice } from './results/resultsSlice';
import { purchaseSlice } from './purchase/purchaseSlice';

export const store = configureStore({
  reducer: {
    results: resultsSlice.reducer,
    purchase: purchaseSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
