import styled from 'styled-components';

export const PurchaseScreenStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
`;

export const Container = styled.div`
  max-width: 1440px;
  width: 80%;
  margin: 0 auto auto auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 20px;
`;

export const FlowContentSection = styled.div`
  margin-top: 50px;

  .header {
    padding: 20px 10px;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
  }
`;
