import styled from 'styled-components';

export const PageLoadingStyles = styled.div`
    background-color: var(--greenia-color);
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #img-container {
        padding: 5px;
        background-color: white;
        border-radius: 100%;
    }

    .content-loading {
        align-items: center;
        justify-content: center;
    }

    img {
        width: 50px;
        height: 50px;
    }

    #text {
        margin-top: 10px;
    }
`;
