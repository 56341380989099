import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { backAxe } from '../../../../services/axios-client/backAxeClient';
import PageLoading from '../../../PageLoading/PageLoading';
import { PaymentContainerStyles, PaymentProofStyled } from './PaymentContainer.styles';
import { BACKEND_BASE_URL } from '../../../../configs/urls';
import { Uploader } from '../../Uploader/Uploader';
import { generalAlertSuccess } from '../../../../utils/alert';

const schema = yup.object({
  card_number: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(16, 'Il campo dovrebbe contenere 16 numeri'),
  card_expiry: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Il formato non è valido')
    .min(5, 'Deve contenere una scadenza valida nel formato specificato')
    .max(5, 'Deve contenere una scadenza valida nel formato specificato'),
  card_cvv: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(3, 'Dovrebbe contenere almeno 3 numeri')
    .max(4, 'Dovrebbe contenere meno di 5 numeri'),
});

const translateError = (error) => {
  const translations = {
    'Invalid expiry month': 'Mese di scadenza non valido',
    'Invalid expiry year': 'Anno di scadenza non valido',
    'Credit card with wrong check-digit': 'Carta di credito con cvv errata',
    'Not numeric characters in credit card number':
      'Caratteri non numerici nel numero della carta di credito',
  };

  if (translations[error] !== undefined) {
    return translations[error];
  }

  return error;
};

export function PaymentContainer({
  distanceToken, quotationId, collectQuoteData,
}) {
  const [quoteError, setQuoteError] = useState(null);
  const [quoteData, setQuoteData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentAuthorizationUrl, setPaymentAuthorizationUrl] = useState(null);

  const {
    register,
    handleSubmit,
    reset: resetCardForm,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const collectQuoteInformation = async () => {
    setLoading(true);
    setQuoteError(null);

    try {
      const response = await backAxe.get(`/request/${distanceToken}/quotations/${quotationId}/for-payment`);

      if (response.status === 200) {
        setQuoteData(response.data.data);
      } else if (response.status === 404) {
        setQuoteError('Quote was not found');
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setQuoteError('An error has occured');
    }
  };

  useEffect(() => {
    collectQuoteInformation();
  }, [quotationId]);

  const submitData = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const response = await backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/payments/pay`, {
        card: {
          number: data.card_number,
          exp_month: data.card_expiry.split('/')[0],
          exp_year: data.card_expiry.split('/')[1],
          cvv: data.card_cvv,
        },
      });

      if (response.status === 400) {
        setError(translateError(response.data.error));
        setLoading(false);
      } else if (response.status === 500) {
        setError(response.data.error);
        setLoading(false);
      } else if (response.status === 200) {
        setPaymentAuthorizationUrl(response.data.data.user_redirect);
        resetCardForm();
        collectQuoteInformation();
      }
    } catch (err) {
      setLoading(false);
      setError('There was an error');
    }
  };

  const uploadPaymentProf = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const fileType = file.type;
      await backAxe.post(`/request/${distanceToken}/quotations/${quotationId}/upload-payment-proof`, {
        document: reader.result.replace(`data:${fileType};base64,`, ''),
        file_type: fileType,
      });

      generalAlertSuccess('Stiamo verificando la prova del pagamento, attendi nostro feedback!');
      collectQuoteData();
    };
  };

  if (loading) {
    return (
      <div style={{
        padding: 20,
        height: 300,
      }}
      >
        <PageLoading />
      </div>
    );
  }

  const paymentsMadeWithCard = quoteData.quotation_payments.filter((p) => p.status === 4).length;
  const paymentsMadeWithBonifico = quoteData.quotation.payment_proofs.filter((p) => p.payment_proof_valid === 1).length;
  const paymentMadeCount = paymentsMadeWithBonifico + paymentsMadeWithCard;
  const totalPaymentsRequired = quoteData.quotation.payment_frequency;

  return (
    <PaymentContainerStyles>
      <div className="custom-row">
        <div className="custom-card">
          {quoteError !== null && <div className="alert alert-error">{quoteError}</div>}

          <div className="invoice">
            <div className="customer-information">
              <div className="field-row">
                <div className="field-name">
                  <b>Nome e cognome</b>
                </div>
                <div className="field-value">
                  {quoteData.insurance_request.customer.name}
                  {' '}
                  {quoteData.insurance_request.customer.surname}
                </div>
              </div>
              <div className="field-row">
                <div className="field-name">
                  <b>Telefono</b>
                </div>
                <div className="field-value">
                  {quoteData.insurance_request.customer.phone}
                </div>
              </div>
              <div className="field-row">
                <div className="field-name">
                  <b>Email</b>
                </div>
                <div className="field-value">
                  {quoteData.insurance_request.customer.email}
                </div>
              </div>
              <div className="field-row">
                <div className="field-name">
                  <b>Indirizzo</b>
                </div>
                <div className="field-value">
                  {quoteData.insurance_request.customer.address}
                  ,
                  {' '}
                  {quoteData.insurance_request.customer.house_number}
                </div>
              </div>
              <div className="field-row">
                <div className="field-name">
                  <b>Frequeza Pagamento</b>
                </div>
                <div className="field-value">
                  {quoteData.quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </div>
              </div>
            </div>

            <h3>Compania</h3>

            <div className="company-info">
              {quoteData.quotation.company_id != null ? (
                <img
                  className="quotation-company-logo"
                  src={BACKEND_BASE_URL + quoteData.quotation.preventivas_company.logo_filename}
                />
              ) : (
                <img
                  className="quotation-company-logo"
                  src={`${BACKEND_BASE_URL}/preventivass/companies/${quoteData.quotation.preventivas_company.logo_filename}`}
                />
              )}
            </div>

            <h3>Premi di polizza</h3>

            <div className="invoice-items">
              <div className="invoice-item">
                <div className="field-row">
                  <div className="field-name">
                    <b>Prodotto:</b>
                    {' '}
                    {quoteData.insurance_request.product.name}
                  </div>
                  <div className="field-value pull-right">
                    {parseFloat(quoteData.quotation.amount).toFixed(2)}
                    {' '}
                    &euro;
                  </div>
                </div>
              </div>

              <div className="invoice-item border-top border-dark">
                <div className="field-row">
                  <div className="field-name">
                    <b>Pagato:</b>
                  </div>
                  <div className="field-value pull-right">
                    {parseFloat(quoteData.quotation.amount_paid ? quoteData.quotation.amount_paid : 0).toFixed(2)}
                    {' '}
                    &euro;
                  </div>
                </div>
              </div>
              {quoteData.quotation.payment_frequency === 2
                && (
                  <div className="invoice-item border-top border-dark">
                    <div className="field-row">
                      <div className="field-name">
                        <b>
                          {quoteData.quotation.amount_paid === null ? 'Primo Pagamento' : 'Secondo Pagamento'}
                          :
                        </b>
                      </div>
                      <div className="field-value pull-right">
                        {quoteData.quotation.amount_paid ? parseFloat(quoteData.quotation.amount).toFixed(2) - parseFloat(quoteData.quotation.amount_paid).toFixed(2) : parseFloat(quoteData.quotation.amount).toFixed(2) / quoteData.quotation.payment_frequency}
                        {' '}
                        &euro;
                      </div>
                    </div>
                  </div>
                )}
              {quoteData.quotation.amount_paid !== null && (
                <div className="invoice-item border-top border-dark">
                  <div className="field-row">
                    <div className="field-name">
                      <b>Prossimo Pagamento:</b>
                    </div>
                    <div className="field-value pull-right">
                      {moment(quoteData.insurance_request.policy_effective_date).add(Math.floor(365 / quoteData.quotation.payment_frequency), 'days').toDate().toLocaleDateString('it-IT')}
                    </div>
                  </div>
                </div>
              )}

            </div>

            <div className="invoice-totals">
              <div className="field-row">
                <div className="field-name">
                  <b>Totale:</b>
                </div>
                <div className="field-value pull-right">
                  <b>
                    {parseFloat(quoteData.quotation.amount).toFixed(2)}
                    {' '}
                    &euro;
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-card">
          {quoteData.quotation.amount_paid === quoteData.quotation.amount
            && (
              <div className="alert alert-success">
                Il preventivo e pagato
              </div>
            )}
          {quoteData.quotation.amount_paid !== quoteData.quotation.amount
            && (
              <>
                <form onSubmit={handleSubmit(submitData)}>
                  {error !== null && <div className="alert alert-error">{error}</div>}

                  <div className="row">
                    <div className="form-group">
                      <h3 className="fw-bold fs-4">
                        Da Pagare:
                        {quoteData.quotation.amount / quoteData.quotation.payment_frequency}
                        {' '}
                        &euro;
                      </h3>
                      <hr />
                      <label>Carta di credito o di debito</label>
                      <input
                        className="form-control"
                        placeholder="Inserisci il numero della carta"
                        {...register('card_number')}
                        maxLength={16}
                      />
                      <div className="error">{errors.card_number?.message}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Data di scadenza</label>
                        <input
                          className="form-control"
                          placeholder="MM/AA"
                          {...register('card_expiry')}
                        />
                        <div className="error">{errors.card_expiry?.message}</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>Codice di sicurezza (CVV)</label>
                        <input
                          className="form-control"
                          placeholder="CVV"
                          {...register('card_cvv')}
                        />
                        <div className="error">{errors.card_cvv?.message}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="submit"
                        value="Conferma"
                        className="confirm-button"
                      />
                    </div>
                  </div>
                </form>

                {quoteData.quotation_payments.length !== 0 && (
                  <div className="mt-3">
                    <h3>Stato del pagamento</h3>
                    {quoteData.quotation_payments.map((payment) => (
                      <div className="payment-record" key={payment.id}>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Carta</b>
                          </div>
                          <div className="field-value">
                            ************
                            {payment.last_four}
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Stato</b>
                          </div>
                          <div className="field-value">{payment.status_name}</div>
                        </div>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Pagato</b>
                          </div>
                          <div className="field-value">
                            {payment.amount.toLocaleString()}
                            {' '}
                            &euro;
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Ora</b>
                          </div>
                          <div className="field-value">
                            {moment(payment.created_at).format('DD.MM.YYYY hh:mm')}
                          </div>
                        </div>
                        {payment.status === 1 && payment.user_redirect !== null
                          && (
                            <div className="field-row">
                              <a target="_blank" href={payment.user_redirect} rel="noreferrer">
                                <div className="btn btn-success">
                                  Autorizza il pagamento
                                </div>
                              </a>

                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
                <hr />

                <h3>Paga con bonifico</h3>
                <ul className="list-group">
                  <li className="list-group-item">Iban: IT60B0326803210052864099270</li>
                  <li className="list-group-item">Titolare: Greenia srl</li>
                  <li className="list-group-item">Causale: Pagamento polizza [nome + cognome]</li>
                </ul>
                {quoteData.quotation.payment_proofs.map((pp, index) => (
                  <PaymentProofStyled key={pp.id}>
                    <h6>
                      Pagemento:
                      {index + 1}
                      {' '}
                      | Pagato:
                      {pp.amount}
                      {' '}
                      &euro;
                    </h6>

                    {(totalPaymentsRequired === 1 || (totalPaymentsRequired === 2 && (paymentsMadeWithBonifico - paymentMadeCount + 1) === index) || (totalPaymentsRequired === 2 && paymentMadeCount === 0))
                      && (
                        <>
                          {pp.payment_proof_valid === 0
                            && (
                              <div className="alert alert-error">
                                La prova del pagamento non e valida
                              </div>
                            )}
                          {pp.payment_proof_valid === 1
                            && (
                              <div className="alert alert-success">
                                La prova del pagamento e valida
                              </div>
                            )}

                          {pp.payment_proof_file !== null && pp.payment_proof_valid === null
                            && <div className="alert alert-warning">Stiamo verificando la prova del pagemento</div>}

                          <embed
                            type={pp.file_type}
                            style={{ marginTop: '10px', width: '100%', minHeight: '250px' }}
                            src={`data:${pp.file_type};base64,${pp.payment_proof_file}`}
                          />
                        </>
                      )}

                  </PaymentProofStyled>
                ))}

                {quoteData.quotation.payment_proofs.find((pp) => pp.payment_proof_valid === null) === undefined
                  && (
                    <div className="mt-3">
                      <Uploader
                        note="CARICA PROVA DEL PAGAMENTO"
                        onFileUpload={(file) => uploadPaymentProf(file)}
                      />
                    </div>
                  )}

              </>
            )}
        </div>
      </div>
      {paymentAuthorizationUrl
        && (
          <div className="x-modal" id="fastquote-modal">
            <div className="x-modal-cover" onClick={() => setPaymentAuthorizationUrl(null)} />
            <div className="general-modal-content">
              <span
                className="general-modal-close"
                title="Quidi"
                onClick={() => setPaymentAuthorizationUrl(null)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
                </svg>
              </span>
              <div className="modal-header">
                <h5 className="modal-title">Autorizza il pagamento</h5>
              </div>
              <div className="x-modal-body">
                <iframe
                  title="Authorization"
                  style={{ width: '100%', height: '70vh' }}
                  src={paymentAuthorizationUrl}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary m-2"
                  onClick={() => setPaymentAuthorizationUrl(null)}
                >
                  Chiudi
                </button>
              </div>
            </div>
          </div>
        )}
    </PaymentContainerStyles>
  );
}
