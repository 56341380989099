import styled from 'styled-components';

export const QuestionarieStyled = styled.div`
    .questionnarie_agreements {
        display: flex;
        flex-direction: column;
    }

    .questionnarie_agreement {
        padding: 20px;
        width: 100%;
        border-bottom: 2px solid var(--greenia-color);
    }

    .agreement_contents {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .agreement_text {
        font-weight: bold;
    }

    .link {
        color: var(--greenia-color);
        text-decoration: underline;
        cursor: pointer;
    }

    .agreement_acceptance {
        width: 100%;
        max-width: 300px;
        padding: 20px;
        display: flex;
        justify-content: space-around;
    }

    .acceptance_button {
        border: 2px solid var(--greenia-color);
        border-radius: 5px;
        color: var(--greenia-color);
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .3s;

        &:hover {
            color: white;
            background-color: var(--greenia-color);
        }

        &.selected {
            color: white;
            background-color: var(--greenia-color);
        }
    }

    .questionnarie_agreement_errors {
        border: 1px solid var(--danger-color);
        border-radius: 5px;
        padding: 10px;
        background-color: rgba(255,0,0,.5);
    }

    .agreement_error {
        color: white;
    }

    .submit-button {
        margin: 20px auto;
        width: 100%;
        max-width: 300px;
        border: none;
        border-radius: 10px;
        background-color: var(--greenia-color);
        color: white;
        font-weight: bold;
        padding: 10px 20px;
        transition: .3s;

        &:hover {
            max-width: 320px;
        }
    }

    @media (max-width: 1000px) {

        .agreement_contents {
            flex-wrap: wrap;
        }

        .agreement_acceptance {
            max-width: unset;
        }
    }
`;
