import React from 'react';
import { AlertBoxStyles } from './AlertBox.styles';

function AlertBox({ text, type }) {
  return (
    <AlertBoxStyles type={type}>
      {text}
    </AlertBoxStyles>
  );
}

export default AlertBox;
